import { GetAllData } from 'interfaces/api.interfaces'
import { TransitionTableRow } from 'modules/transitionTable/api'
import { PaginateData } from 'packages/react-query'

export enum TransitionTabsCodes {
  All = 'all',
  Completed = 'completed',
}

export type TransitionAllData = Record<TransitionTabsCodes, PaginateData<GetAllData<TransitionTableRow>> | undefined>
