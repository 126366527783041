export enum Routes {
  Main = '/',
  Login = '/login',
  ResetPassword = '/reset-password',
  DataSources = '/data',
  MasterData = '/master-data',
  MasterDataHierarchy = '/master-data/:hierarchyId',
  Status = '/status',
  InnovationManagement = '/innovation-management',
  Forecast = '/project/:projectId/forecast',
  ReplenOptimizer = '/replen-optimizer',
  Approvals = '/approvals',
  PromoOptimizer = '/promo-optimizer',
  CreatePromoScenario = '/create-promo-scenario',
  CreateReplenScenario = '/create-replen-scenario',
  PromoScenario = '/promo-optimizer/promo-scenario/:scenarioId',
  PromoScenarioNotFound = '/promo-optimizer/promo-scenario/not-found',
  ReplenScenario = '/replen-optimizer/replen-scenarios/:scenarioId',
  ReplenScenarioNotFound = '/replen-optimizer/replen-scenarios/not-found',
  ReplenWarehouses = '/replen-optimizer/replen-warehouses',
  ReplenScenarios = '/replen-optimizer/replen-scenarios',
  ReplenOverview = '/replen-optimizer/replen-overview',
  OptimizerDemo = '/promo-optimizer-demo',
  CreateRtmScenario = '/create-rtm-scenario',
  RtmScenario = '/rtm-optimizer/rtm-scenario/:scenarioId',
  RtmOptimizer = '/rtm-optimizer',
  RtmScenarioNotFound = '/rtm-optimizer/rtm-scenario/not-found',
  CreateTetrisScenario = '/create-tetris-scenario',
  TetrisScenario = '/tetris-optimizer/tetris-scenario/:scenarioId',
  TetrisOptimizer = '/tetris-optimizer',
  TetrisScenarioNotFound = '/tetris-optimizer/tetris-scenario/not-found',
  CreateCfrScenario = '/create-cfr-scenario',
  CfrScenario = '/cfr-optimizer/cfr-scenario/:scenarioId',
  CfrOptimizer = '/cfr-optimizer',
  CfrScenarioNotFound = '/cfr-optimizer/cfr-scenario/not-found',
  TwoFactorExpired = '/two-factor-expired',
  PrgmCreate = '/prgm/create',
  Selections = '/selections',
  Selection = '/selections/:selectionId',
  NewSelection = '/selections/new',
}

export const forecastRoutes = [
  Routes.Main,
  Routes.Forecast,
  Routes.Status,
  Routes.InnovationManagement,
  Routes.MasterData,
  Routes.DataSources,
  Routes.OptimizerDemo,
]

export const promoOptimizerRoutes = [
  Routes.PromoOptimizer,
  Routes.CreatePromoScenario,
  Routes.PromoScenario,
  Routes.PromoScenarioNotFound,
]

export const replenOptimizerRoutes = [
  Routes.ReplenOptimizer,
  Routes.CreateReplenScenario,
  Routes.ReplenScenario,
  Routes.ReplenScenarioNotFound,
  Routes.ReplenWarehouses,
  Routes.ReplenScenarios,
  Routes.ReplenOverview,
]

export const rtmOptimizerRoutes = [
  Routes.CreateRtmScenario,
  Routes.RtmScenario,
  Routes.RtmOptimizer,
  Routes.RtmScenarioNotFound,
]

export const tetrisOptimizerRoutes = [
  Routes.CreateTetrisScenario,
  Routes.TetrisScenario,
  Routes.TetrisOptimizer,
  Routes.TetrisScenarioNotFound,
]

export const cfrOptimizerRoutes = [
  Routes.CreateCfrScenario,
  Routes.CfrScenario,
  Routes.CfrOptimizer,
  Routes.CfrScenarioNotFound,
]
