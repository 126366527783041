import { KawaseBlurFilter } from '@pixi/filter-kawase-blur'
import * as PIXI from 'pixi.js'
import { createNoise2D } from 'simplex-noise'

function random(min: number, max: number) {
  return Math.random() * (max - min) + min
}

function map(n: number, start1: number, end1: number, start2: number, end2: number) {
  return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2
}

const noise2D = createNoise2D()

class ColorPalette {
  colorChoices: string[]

  constructor(colors: string[]) {
    this.colorChoices = colors
  }

  randomColor() {
    return Number(this.colorChoices[random(0, 10) <= 4 ? 0 : ~~random(0, this.colorChoices.length)].replace('#', '0x'))
  }
}

class Orb {
  canvasElement: HTMLCanvasElement | null
  originX: number
  originY: number
  bounds: Record<'x' | 'y', { min: number; max: number }>
  x: number
  y: number
  scale: number
  fill: number
  radius: number
  xOff: number
  yOff: number
  inc: number
  graphics: PIXI.Graphics

  constructor(fill = 0x000000, canvasElement: HTMLCanvasElement | null = null, speed = 1) {
    this.canvasElement = canvasElement

    this.originX = this.canvasElement?.offsetWidth || 0
    this.originY = this.canvasElement?.offsetHeight || 0

    this.bounds = this.setBounds()
    this.x = random(this.bounds.x.min, this.bounds.x.max)
    this.y = random(this.bounds.y.min, this.bounds.y.max)

    this.scale = 1

    this.fill = fill

    const minSide = Math.min(this.originX, this.originY)
    this.radius = random(minSide / 4, minSide / 2)

    this.xOff = random(0, 1000)
    this.yOff = random(0, 1000)

    // speed
    this.inc = 0.0008 * speed

    this.graphics = new PIXI.Graphics()
    this.graphics.alpha = 0.825
  }

  setBounds() {
    const maxDist = Math.min(this.originX, this.originY) / 2

    return {
      x: {
        min: 0 - maxDist,
        max: this.originX + maxDist,
      },
      y: {
        min: 0 - maxDist,
        max: this.originY + maxDist,
      },
    }
  }

  update() {
    const xNoise = noise2D(this.xOff, this.xOff)
    const yNoise = noise2D(this.yOff, this.yOff)
    const scaleNoise = noise2D(this.xOff, this.yOff)

    this.x = map(xNoise, -1, 1, this.bounds.x.min, this.bounds.x.max)
    this.y = map(yNoise, -1, 1, this.bounds.y.min, this.bounds.y.max)

    this.scale = map(scaleNoise, -1, 1, 0.5, 1)

    this.xOff += this.inc * (this.originY / this.originX)
    this.yOff += this.inc
  }

  render() {
    this.graphics.x = this.x
    this.graphics.y = this.y
    this.graphics.scale.set(this.scale)

    this.graphics.clear()

    this.graphics.beginFill(this.fill)
    this.graphics.drawCircle(0, 0, this.radius)
    this.graphics.endFill()
  }
}

export const createAnimationBackground = (
  canvasElement: HTMLCanvasElement,
  speed = 1,
  colors: string[] = [],
  blur = 0.1,
  numberOfCircles = 50,
) => {
  const width = canvasElement.offsetWidth
  const height = canvasElement.offsetHeight

  const app = new PIXI.Application({
    view: canvasElement,
    backgroundAlpha: 0,
    width,
    height,
  })

  const colorPalette = new ColorPalette(colors)

  app.stage.filters = [new KawaseBlurFilter(Math.min(width, height) * blur, 10, true)]

  const orbs: Orb[] = []

  for (let i = 0; i < numberOfCircles; i++) {
    const orb = new Orb(colorPalette.randomColor(), canvasElement, speed)
    app.stage.addChild(orb.graphics)
    orbs.push(orb)
  }

  // Animate
  if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
    app.ticker.add(() => {
      orbs.forEach((orb) => {
        orb.update()
        orb.render()
      })
    })
  } else {
    orbs.forEach((orb) => {
      orb.update()
      orb.render()
    })
  }

  return app
}
