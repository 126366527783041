import { toast } from 'react-toastify'

import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { getErrorMessage, ToastError } from 'packages/react-query'

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      const showToast = typeof query.meta?.error === 'object' ? query.meta?.error?.showToast ?? true : true
      if (showToast) {
        const errorField = typeof query.meta?.error === 'function' ? query.meta?.error(error) : query.meta?.error
        const errorQuery = typeof errorField === 'object' ? errorField?.message : errorField
        const errorMessage = typeof errorQuery !== 'undefined' ? errorQuery : getErrorMessage(error)
        if (errorMessage) {
          toast.error(errorMessage)
        }
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, context, mutation) => {
      const showToast = typeof mutation.meta?.error === 'object' ? mutation.meta?.error?.showToast ?? true : true
      if (showToast) {
        const errorField =
          typeof mutation.meta?.error === 'function' ? mutation.meta?.error(error) : mutation.meta?.error
        const errorQuery = typeof errorField === 'object' ? errorField?.message : errorField
        const errorMessage = typeof errorQuery !== 'undefined' ? errorQuery : getErrorMessage(error)
        if (errorMessage) {
          toast.error(errorMessage)
        }
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: Infinity,
    },
    mutations: {
      retry: false,
      onError: (error) => {
        if ((error as ToastError).showToast) {
          toast.error((error as ToastError).message)
        }
      },
    },
  },
})
