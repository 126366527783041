import { generatePath, matchPath, useLocation, useNavigate } from 'react-router-dom'

import { useCfrScenariosParams } from 'modules/cfrScenario/store'
import { DataSourcesQueries } from 'modules/dataSources/queries/types'
import { useCurrentDelistingTab, useDelistingErrors, useUpdateDelistingTable } from 'modules/delistingTable/store'
import { useClearCashForecast } from 'modules/forecast/handlers'
import { useSavedManualCorrections, useUnsavedManualCorrections } from 'modules/forecast/store'
import { useAppliedForecastFilters } from 'modules/forecastFilters/store'
import { useCurrentGranularity } from 'modules/granularities/store'
import { useICActions, useUpdateICTable } from 'modules/innovationCalculator/store'
import { useClearInnovationDashboard } from 'modules/innovationDashboard/handlers'
import { useAppliedIDFilters } from 'modules/innovationDashboardFilters/store'
import { useCurrentInnovationTab, useInnovationErrors, useUpdateInnovationTable } from 'modules/innovationTable/store'
import { useClearCashMasterData } from 'modules/masterData/handlers'
import { usePromoScenariosParams } from 'modules/promoScenario/store'
import { PromoScenarioOutputQueries } from 'modules/promoScenarioOutput/queries/types'
import { useCurrentPromoTab, usePromoErrors, useUpdatePromoTable } from 'modules/promoTable/store'
import { RecommendationsQueries } from 'modules/recommendations/queries/types'
import { useRtmScenariosParams } from 'modules/rtmScenario/store'
import { useClearCashStatusDashboard } from 'modules/statusDashboard/handlers'
import { useAppliedStatusFilters } from 'modules/statusFilters/store'
import { useCurrentStatusTab } from 'modules/statusTable/store'
import { useTetrisScenariosParams } from 'modules/tetrisScenario/store'
import { useCurrentTransitionTab, useTransitionErrors, useUpdateTransitionTable } from 'modules/transitionTable/store'
import { UserQueries } from 'modules/user/queries/types'
import { useQueryClient } from 'packages/react-query'
import {
  cfrOptimizerRoutes,
  promoOptimizerRoutes,
  replenOptimizerRoutes,
  Routes,
  rtmOptimizerRoutes,
  tetrisOptimizerRoutes,
} from 'routes/routes'
import { cacheForCallback } from 'utils/memoize'

export const useClearAppCash = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    clearFilters: clearPromoFilters,
    clearSearch: clearPromoSearch,
    clearOrdering: clearPromoOrdering,
  } = usePromoScenariosParams()
  const {
    clearFilters: clearRtmFilters,
    clearSearch: clearRtmSearch,
    clearOrdering: clearRtmOrdering,
  } = useRtmScenariosParams()
  const {
    clearFilters: clearTetrisFilters,
    clearSearch: clearTetrisSearch,
    clearOrdering: clearTetrisOrdering,
  } = useTetrisScenariosParams()
  const {
    clearFilters: clearCfrFilters,
    clearSearch: clearCfrSearch,
    clearOrdering: clearCfrOrdering,
  } = useCfrScenariosParams()

  const { clearCashStatusDashboard } = useClearCashStatusDashboard()
  const { clearInnovationDashboard } = useClearInnovationDashboard()
  const { clearCashMasterData } = useClearCashMasterData()
  const { clearCashForecast } = useClearCashForecast()

  const clearSavedManualCorrections = useSavedManualCorrections((state) => state.clear)
  const clearUnsavedManualCorrections = useUnsavedManualCorrections((state) => state.clear)
  const clearGranularityBlockA = useCurrentGranularity((state) => state.blockA.clear)
  const clearGranularityBlockB = useCurrentGranularity((state) => state.blockB.clear)
  const clearForecastFilters = useAppliedForecastFilters((state) => state.clearAppliedFilters)
  const clearInnovationTableErrors = useInnovationErrors((state) => state.clear)
  const clearTransitionTableErrors = useTransitionErrors((state) => state.clear)
  const clearDelistingTableErrors = useDelistingErrors((state) => state.clear)
  const clearPromoTableErrors = usePromoErrors((state) => state.clear)
  const clearICTable = useUpdateICTable((state) => state.clearCash)
  const clearICActions = useICActions((state) => state.clear)
  const clearTransitionTable = useUpdateTransitionTable((state) => state.clear)
  const clearPromoTable = useUpdatePromoTable((state) => state.clear)
  const clearInnovationTable = useUpdateInnovationTable((state) => state.clear)
  const clearDelistingTable = useUpdateDelistingTable((state) => state.clear)
  const clearIDFilters = useAppliedIDFilters((state) => () => state.clear)
  const clearIDFiltersInputs = useAppliedIDFilters((state) => () => state.clearFilterInputs)
  const clearStatusFilters = useAppliedStatusFilters((state) => () => state.clear)
  const clearStatusFiltersInputs = useAppliedStatusFilters((state) => () => state.clearFilterInputs)
  const clearStatusTab = useCurrentStatusTab((state) => state.clear)
  const clearDelistingTab = useCurrentDelistingTab((state) => state.clear)
  const clearInnovationTab = useCurrentInnovationTab((state) => state.clear)
  const clearPromoTab = useCurrentPromoTab((state) => state.clear)
  const clearTransitionTab = useCurrentTransitionTab((state) => state.clear)

  const isHierarchyPage = !!matchPath({ path: Routes.MasterDataHierarchy }, pathname)
  const isForecastPage = !!matchPath({ path: Routes.Forecast }, pathname)
  const isPromoScenarioPages =
    promoOptimizerRoutes.includes(pathname as Routes) || !!matchPath({ path: Routes.PromoScenario }, pathname)
  const isRtmScenarioPages =
    rtmOptimizerRoutes.includes(pathname as Routes) || !!matchPath({ path: Routes.RtmScenario }, pathname)
  const isTetrisScenarioPages =
    tetrisOptimizerRoutes.includes(pathname as Routes) || !!matchPath({ path: Routes.TetrisScenario }, pathname)
  const isCfrScenarioPages =
    cfrOptimizerRoutes.includes(pathname as Routes) || !!matchPath({ path: Routes.CfrScenario }, pathname)
  const isReplenScenarioPages =
    replenOptimizerRoutes.includes(pathname as Routes) || !!matchPath({ path: Routes.ReplenScenario }, pathname)

  // Удалить данные пользовательского ввода
  const clearEditData = () => {
    clearUnsavedManualCorrections()
    clearForecastFilters()
    clearICTable()
    clearICActions()
    clearTransitionTable()
    clearPromoTable()
    clearInnovationTable()
    clearDelistingTable()
    clearIDFilters()
    clearIDFiltersInputs()
    clearStatusFilters()
    clearStatusFiltersInputs()
  }

  const clearAppCash = (newProjectId: number) => {
    cacheForCallback.clear()

    queryClient.refetchQueries({ queryKey: [UserQueries.Profile] })
    queryClient.removeQueries({ queryKey: [RecommendationsQueries.RecommendationsTable] })
    queryClient.removeQueries({ queryKey: [DataSourcesQueries.DataSources] })
    queryClient.removeQueries({ queryKey: [DataSourcesQueries.DataStatus] })
    queryClient.removeQueries({ queryKey: [PromoScenarioOutputQueries.PromoScenarioResult] })

    clearCashStatusDashboard()
    clearInnovationDashboard()
    clearCashMasterData()
    clearCashForecast()
    clearPromoFilters()
    clearPromoSearch()
    clearPromoOrdering()
    clearRtmFilters()
    clearRtmSearch()
    clearRtmOrdering()
    clearSavedManualCorrections()
    clearGranularityBlockA()
    clearGranularityBlockB()
    clearTetrisFilters()
    clearTetrisSearch()
    clearTetrisOrdering()
    clearCfrFilters()
    clearCfrSearch()
    clearCfrOrdering()
    clearEditData()
    clearInnovationTableErrors()
    clearTransitionTableErrors()
    clearPromoTableErrors()
    clearDelistingTableErrors()
    clearStatusTab()
    clearDelistingTab()
    clearInnovationTab()
    clearPromoTab()
    clearTransitionTab()

    if (isHierarchyPage) {
      navigate(Routes.MasterData)
    }
    if (isForecastPage) {
      navigate(generatePath(Routes.Forecast, { projectId: String(newProjectId) }))
    }
    if (isPromoScenarioPages) {
      navigate(generatePath(Routes.PromoOptimizer))
    }
    if (isRtmScenarioPages) {
      navigate(generatePath(Routes.RtmOptimizer))
    }
    if (isTetrisScenarioPages) {
      navigate(generatePath(Routes.TetrisOptimizer))
    }
    if (isCfrScenarioPages) {
      navigate(generatePath(Routes.CfrOptimizer))
    }
    if (isReplenScenarioPages) {
      navigate(generatePath(Routes.ReplenOptimizer))
    }
  }

  return { clearAppCash, clearEditData }
}
