import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useTranslate } from '@ayub-begimkulov/i18n'
import { TextModal } from 'components/modals/TextModal'
import { useLogout } from 'hooks/useLogout'
import { useModal } from 'hooks/useModal'
import { useAuthenticated } from 'modules/auth/handlers'
import { useNavigateToLastPage } from 'modules/session/handlers/useNavigateToLastPage'
import { useSessionOver } from 'modules/session/handlers/useSessionOver'
import { useLastSession } from 'modules/session/store'
import { useClearAppCash, useProfile } from 'modules/user/queries'
import { Button, ButtonColors } from 'ui/Button'

export const SessionOver = () => {
  const [openSessionOver, onOpenSessionOver, onCloseSessionOver] = useModal()
  const { authenticated } = useAuthenticated()
  const setPage = useLastSession((state) => state.setPage)
  const setActiveProjectId = useLastSession((state) => state.setActiveProjectId)
  const lastActiveProjectId = useLastSession((state) => state.activeProjectId)
  const { clearEditData } = useClearAppCash()
  const { profile } = useProfile()
  const { logout } = useLogout()
  const location = useLocation()
  const setProfileId = useLastSession((state) => state.setProfileId)
  const profileId = useLastSession((state) => state.profileId)
  const { navigateToLastPage } = useNavigateToLastPage()

  const closeSessionOverModal = () => {
    onCloseSessionOver()
    logout()
  }
  useSessionOver(onOpenSessionOver)
  const translate = useTranslate()

  useEffect(() => {
    if (!authenticated || !profile) {
      return
    }
    setPage(location.pathname)
  }, [location, profile])

  useEffect(() => {
    if (!profile) {
      return
    }
    if (profile.id === profileId) {
      navigateToLastPage()
    } else {
      clearEditData()
    }
    setProfileId(profile.id)

    if (!profile?.activeProjectId) {
      return
    }
    if (profile.activeProjectId !== lastActiveProjectId) {
      setActiveProjectId(profile.activeProjectId)
      clearEditData()
    }
  }, [profile])

  return (
    <TextModal isOpened={openSessionOver} onClose={closeSessionOverModal} title={translate('sessionOver')}>
      <Button color={ButtonColors.Primary} onClick={closeSessionOverModal}>
        Ok
      </Button>
    </TextModal>
  )
}
