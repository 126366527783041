import { useQuery } from '@tanstack/react-query'
import { IS_LOWCODE_ENABLED } from 'constants/env'
import { editModeApi } from 'modules/editMode/api'
import { WidgetCategoryTypes } from 'modules/editMode/interfaces'
import { useProfile } from 'modules/user/queries'
import { noRetryOnError } from 'packages/react-query'

import { WidgetsQueries } from './types'

export const useWidgets = (category: WidgetCategoryTypes) => {
  const { profile } = useProfile()
  const enabled = !!profile && IS_LOWCODE_ENABLED

  const { data } = useQuery({
    queryKey: [WidgetsQueries.Forecast],
    queryFn: () => editModeApi.getEditModeSettings(category),
    enabled,
    ...noRetryOnError,
  })

  return {
    widgets: data?.widgets ?? [],
    categoryId: data?.categoryId,
  }
}
