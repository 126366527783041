import { forwardRef, MouseEventHandler, ReactNode, useEffect, useState } from 'react'

import cx from 'clsx'
import { ButtonHTMLTypes } from 'interfaces/components.interfaces'
import { Loader, LoaderColors } from 'ui/Loader'

import classes from './Button.module.scss'

export enum ButtonColors {
  Default = 'default',
  Gray = 'gray',
  Light = 'light',
  Danger = 'danger',
  White = 'white',
  Border = 'border',
  BorderWhite = 'borderWhite',
  Text = 'text',
  Primary = 'primary',
  PrimaryWhite = 'primaryWhite',
}

const LoaderDependence = {
  [ButtonColors.Default]: LoaderColors.White,
  [ButtonColors.Gray]: LoaderColors.Dark,
  [ButtonColors.Light]: LoaderColors.Dark,
  [ButtonColors.Danger]: LoaderColors.White,
  [ButtonColors.White]: LoaderColors.Dark,
  [ButtonColors.Border]: LoaderColors.Dark,
  [ButtonColors.BorderWhite]: LoaderColors.White,
  [ButtonColors.Text]: LoaderColors.Dark,
  [ButtonColors.Primary]: LoaderColors.White,
  [ButtonColors.PrimaryWhite]: LoaderColors.Default,
}

export enum ButtonSizes {
  Default = 'default',
  Medium = 'medium',
  Small = 'small',
}

interface ButtonProps {
  className?: string
  color?: ButtonColors
  size?: ButtonSizes
  fullWidth?: boolean
  onClick?: MouseEventHandler
  disabled?: boolean
  loading?: boolean
  htmlType?: ButtonHTMLTypes
  children?: ReactNode
  icon?: ReactNode
  stopPropagation?: boolean
  isRounded?: boolean
  isSmallRounded?: boolean
  notStyleOnDisabled?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      color = ButtonColors.Default,
      size = ButtonSizes.Default,
      fullWidth,
      children,
      onClick,
      disabled,
      loading,
      htmlType = 'button',
      icon,
      stopPropagation,
      isRounded,
      isSmallRounded,
      notStyleOnDisabled,
    },
    ref,
  ) => {
    const [loadingInternal, setLoadingInternal] = useState(false)

    const onClickInternal: MouseEventHandler = (e) => {
      if (stopPropagation) {
        e.stopPropagation()
      }
      if (onClick) {
        onClick(e)
      }
    }

    useEffect(() => {
      setLoadingInternal(!!loading)
    }, [loading])

    return (
      <button
        className={cx(classes.button, className, classes[color], classes[size], {
          [classes.full]: fullWidth,
          [classes.loading]: loadingInternal,
          [classes.rounded]: isRounded,
          [classes.smallRounded]: isSmallRounded,
          [classes.notStyleOnDisabled]: notStyleOnDisabled,
        })}
        disabled={disabled}
        onClick={onClickInternal}
        ref={ref}
        type={htmlType}
      >
        {loadingInternal && <Loader className={classes.loader} color={LoaderDependence[color]} />}
        <span className={classes.flex}>
          {icon && <div className={cx(classes.icon, { [classes.center]: !children })}>{icon}</div>}
          {children}
        </span>
      </button>
    )
  },
)
