import { axios } from 'config/axios'
import { GetArrayResponse, GetOneResponse } from 'interfaces/api.interfaces'

import { userEndpoints } from './endpoints'
import { Permission, Profile, ProfileUpdateData } from './interfaces'

const getProfile = async (): GetOneResponse<Profile> => {
  const { data } = await axios.request<Profile>({
    method: 'GET',
    url: userEndpoints.getInfo(),
  })
  return data
}

const getPermissions = async (): GetArrayResponse<Permission> => {
  const { data } = await axios.request<Permission[]>({
    method: 'GET',
    url: userEndpoints.getPermissions(),
  })
  return data
}

const updateInfo = async (data: ProfileUpdateData): GetOneResponse<Profile> => {
  const { data: response } = await axios.request<Profile>({
    method: 'PATCH',
    url: userEndpoints.updateInfo(),
    data,
  })

  return response
}

export const userApi = {
  getProfile,
  getPermissions,
  updateInfo,
}
