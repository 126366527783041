import { FC, ReactNode } from 'react'

import cx from 'clsx'
import { Close } from 'ui/Close'

import classes from './Modal.module.scss'

interface ModalProps {
  className?: string
  classNameClose?: string
  classNameTitle?: string
  title?: ReactNode
  onClose?: () => void
  children?: ReactNode
}

export const Modal: FC<ModalProps> = ({ className, classNameClose, classNameTitle, title, onClose, children }) => (
  <div className={cx(classes.wrap, className)} data-scroll="">
    <Close className={classNameClose} onClick={onClose} />
    {title && <div className={cx(classes.title, classNameTitle)}>{title}</div>}
    {children}
  </div>
)
