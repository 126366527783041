import { FC } from 'react'
import { useDrop } from 'react-dnd'

import cx from 'clsx'
import { useTranslate } from 'config/i18n'
import { EditModeWidgetBlock } from 'modules/editMode/components/EditModeWidgetBlock'
import { useUpdateWidgetsLocal } from 'modules/editMode/handlers'
import { useWidgetsFiltered } from 'modules/editMode/handlers/useWidgetsFiltered'
import { DragAreaName, DraggableItem } from 'modules/editMode/interfaces'
import { SlideToggle } from 'ui/SlideToggle'

import classes from './EditModeWidgets.module.scss'

interface EditModeWidgetsProps {
  className?: string
}

export const EditModeWidgets: FC<EditModeWidgetsProps> = ({ className }) => {
  const translate = useTranslate()
  const { widgetsFiltered } = useWidgetsFiltered()
  const { changeWidgetOrderInternal, activeWidgetsCount } = useUpdateWidgetsLocal()

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DragAreaName.Forecast,
      hover: (elem: DraggableItem) => {
        const firstActiveItem = [...widgetsFiltered].reverse().find((item) => item.order === 0)
        changeWidgetOrderInternal?.(elem.id, firstActiveItem)
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [widgetsFiltered],
  )

  return (
    <div className={cx(classes.wrap, className)}>
      <div className={cx(classes.lineWrap, { [classes.fullHeight]: !activeWidgetsCount })} ref={drop}>
        {!activeWidgetsCount && (
          <SlideToggle open={!isOver}>
            <div className={cx(classes.emptyText)}>{translate('editMode.emptyWidgetsMessage')}</div>
          </SlideToggle>
        )}
        <div className={cx(classes.line, { [classes.showLine]: isOver })} />
      </div>
      {widgetsFiltered
        .filter((item) => !!item.order)
        .sort((a, b) => a.order - b.order)
        .map((item) => (
          <EditModeWidgetBlock item={item} key={item.id} />
        ))}
    </div>
  )
}
