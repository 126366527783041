export const defaultPerPage = 30
export const dataSourcesPerPage = 100
export const statusDashboardPerPage = 15
export const innovationDashboardPerPage = 15
export const promoScenariosList = 25
export const masterDataPerPage = 20
export const promoResultsLimit = 50
export const replenScenariosList = 20
export const replenScenariosOutputRowsLimit = 50
export const workflowDashboardPerPage = 5
export const rtmScenariosList = 25
export const tetrisScenariosList = 25
export const cfrScenariosList = 25
export const cfrResultsLimit = 50
export const selectionsPerPage = 50
