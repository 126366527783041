import { useQuery } from '@tanstack/react-query'
import { useTranslate } from 'config/i18n'
import { useAuthenticated } from 'modules/auth/handlers'
import { userApi } from 'modules/user/api/methods'
import { getError, noRetryOnError } from 'packages/react-query'

import { UserQueries } from './types'

export const useProfile = () => {
  const { authenticated } = useAuthenticated()
  const translate = useTranslate()

  const { isPending, error, data, isFetched } = useQuery({
    queryKey: [UserQueries.Profile],
    queryFn: userApi.getProfile,
    enabled: authenticated,
    ...noRetryOnError,
    meta: {
      error: {
        message: translate('errorsNoData.profile'),
        showToast: false,
      },
    },
  })

  return {
    authenticated,
    profileError: getError(error),
    isLoadingProfile: isPending,
    profile: data,
    scenarioId: data?.scenarioId,
    isFetchedProfile: isFetched,
  }
}
