import { useClearCashHierarchyTree } from 'modules/hierarchyTree/handlers'
import { MasterDataQueries } from 'modules/masterData/queries/types'
import { useQueryClient } from 'packages/react-query'

export const useClearCashMasterData = () => {
  const queryClient = useQueryClient()
  const { clearHierarchyTree } = useClearCashHierarchyTree()

  const clearCashMasterData = () => {
    queryClient.removeQueries({ queryKey: [MasterDataQueries.MasterDataHierarchyList] })
    queryClient.removeQueries({ queryKey: [MasterDataQueries.MasterDataCountArchived] })
    queryClient.removeQueries({ queryKey: [MasterDataQueries.MasterDataHierarchies] })
    clearHierarchyTree()
  }

  return { clearCashMasterData }
}
