import { devtools } from 'config/devtools'
import { UseAppliedIDFilters } from 'modules/innovationDashboardFilters/store/useAppliedIDFilters/types'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useAppliedIDFilters = create<UseAppliedIDFilters>()(
  devtools(
    persist(
      (set) => ({
        filters: null,
        set: (filters) => set({ filters }),
        clear: () => set({ filters: null }),
        filterInputs: {},
        setFilterInputs: (filterInputs) => set((prev) => ({ filterInputs: { ...prev.filterInputs, ...filterInputs } })),
        clearFilterInputs: () => set({ filterInputs: {} }),
      }),
      {
        name: 'innovationDashboardFilters',
      },
    ),
    {
      store: 'innovationDashboardFilters',
    },
  ),
)
