import { RefObject } from 'react'

export const renderElement = (element?: RefObject<HTMLElement>, counter = 20) =>
  new Promise((resolve) => {
    requestAnimationFrame(async () => {
      if (element?.current || !counter) {
        resolve(1)
      } else {
        renderElement(element, counter - 1).then(resolve)
      }
    })
  })
