import { devtools } from 'config/devtools'
import { deleteEmpty, deleteNullableInArray, deleteUndefined } from 'packages/helper'
import { removeByIndex } from 'packages/helper/src/removeByIndex'
import { getTomorrowTimestamp, toMidnight } from 'utils/date'
import { v4 as uuid } from 'uuid'
import { create } from 'zustand'
import { persist, subscribeWithSelector } from 'zustand/middleware'

import { UseUpdateDelistingTable } from './types'

export const useUpdateDelistingTable = create<UseUpdateDelistingTable>()(
  devtools(
    subscribeWithSelector(
      persist(
        (set) => ({
          updated: [],
          created: [],
          lastSavedErrorData: {
            updated: [],
            created: [],
          },
          createRow: () =>
            set(({ created }) => ({
              created: [
                ...created,
                {
                  idCreated: uuid(),
                  date: '',
                },
              ],
            })),
          setLastSavedErrorData: (lastSavedErrorData) => set(() => ({ lastSavedErrorData })),
          updateMasterDataElement: (masterDataElementId, id, type) => {
            if (!masterDataElementId && type === 'created') {
              set(({ created }) => {
                const rowIndex = created.findIndex((item) => item.idCreated === id)
                if (rowIndex > -1) {
                  delete created[rowIndex].masterDataElementId
                }
                return { created }
              })
              return
            }
            if (masterDataElementId && type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        masterDataElementId,
                      }
                    : item,
                ),
              }))
            }
          },
          updateDate: (date, id, type, initialRow, delistingId, inactiveId) => {
            if (!date && type === 'updated') {
              set(({ updated }) => {
                const rowIndex = updated.findIndex((item) => item.id === id)
                if (rowIndex > -1) {
                  delete updated[rowIndex].date
                  delete updated[rowIndex].statusId
                  if (!Object.keys(deleteEmpty({ ...updated[rowIndex], id: null })).length) {
                    updated.splice(rowIndex, 1)
                  }
                }
                return { updated }
              })
              return
            }
            if (!date && type === 'created') {
              set(({ created }) => {
                const rowIndex = created.findIndex((item) => item.idCreated === id)
                if (rowIndex > -1) {
                  created[rowIndex].date = ''
                  delete created[rowIndex].statusId
                  if (!Object.keys(deleteEmpty({ ...created[rowIndex], id: null, idCreated: null })).length) {
                    created.splice(rowIndex, 1)
                  }
                }
                return { created }
              })
              return
            }
            if (date && type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.date = date
                  if (row.statusId && initialRow?.masterDataElement?.manualStatus?.id === row.statusId) {
                    delete row.statusId
                  } else if (initialRow && inactiveId && delistingId) {
                    const statusId =
                      toMidnight(new Date(date)).getTime() < getTomorrowTimestamp() ? inactiveId : delistingId
                    if (initialRow?.masterDataElement?.manualStatus?.id !== statusId) {
                      row.statusId =
                        toMidnight(new Date(date)).getTime() < getTomorrowTimestamp() ? inactiveId : delistingId
                    } else {
                      delete row.statusId
                    }
                  }
                } else {
                  const statusId =
                    toMidnight(new Date(date)).getTime() < getTomorrowTimestamp() ? inactiveId : delistingId
                  updated.push(
                    deleteUndefined({
                      id: id as number,
                      date,
                      statusId:
                        statusId && initialRow?.masterDataElement?.manualStatus?.id === statusId ? undefined : statusId,
                    }),
                  )
                }
                return { updated }
              })
            }
            if (date && type === 'created') {
              const statusId = toMidnight(new Date(date)).getTime() < getTomorrowTimestamp() ? inactiveId : delistingId
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? deleteUndefined({
                        ...item,
                        date,
                        statusId,
                      })
                    : item,
                ),
              }))
            }
          },
          updateStatus: (statusId, id, type) => {
            if (!statusId && type === 'updated') {
              set(({ updated }) => {
                const rowIndex = updated.findIndex((item) => item.id === id)
                if (rowIndex > -1) {
                  delete updated[rowIndex].statusId
                  if (!Object.keys(deleteEmpty({ ...updated[rowIndex], id: null })).length) {
                    updated.splice(rowIndex, 1)
                  }
                }
                return { updated }
              })
              return
            }
            if (statusId && type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.statusId = statusId
                } else {
                  updated.push({ id: id as number, statusId })
                }
                return { updated }
              })
            }
          },
          updateStatuses: (statusId, rows) => {
            const updatedRows = rows.filter((item) => item.type === 'updated').map((item) => item.id)
            if (!statusId) {
              set(({ updated }) => ({
                updated: deleteNullableInArray(
                  updated.map((item) => {
                    if (updatedRows.includes(item.id)) {
                      delete item.statusId
                      if (!Object.keys(deleteEmpty({ ...item, id: null })).length) {
                        return null
                      }
                    }
                    return item
                  }),
                ),
              }))
            } else {
              set(({ updated }) => {
                const newUpdatedIds = updatedRows.filter(
                  (updatedId) => !updated.map((item) => item.id).includes(updatedId as number),
                )
                return {
                  updated: [
                    ...updated.map((item) => {
                      if (updatedRows.includes(item.id)) {
                        item.statusId = statusId
                      }
                      return item
                    }),
                    ...newUpdatedIds.map((updatedId) => ({ id: updatedId as number, statusId })),
                  ],
                }
              })
            }
          },
          removeNew: (index) => set(({ created }) => ({ created: removeByIndex(created, index) })),
          clear: () => set({ updated: [], created: [], lastSavedErrorData: { updated: [], created: [] } }),
          lastSavedError: null,
          setLastSavedError: (lastSavedError) => set(() => ({ lastSavedError })),
        }),
        {
          name: 'delistingTable',
        },
      ),
    ),
    {
      store: 'delistingTable',
    },
  ),
)
