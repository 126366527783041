import { FC, useState } from 'react'

import cx from 'clsx'
import { useTranslate } from 'config/i18n'
import { EditModeMenuWidgets } from 'modules/editMode/components/EditModeMenuWidgets'
import { useUpdateWidgetsLocal } from 'modules/editMode/handlers'
import { WidgetCategoryTypes } from 'modules/editMode/interfaces'
import { useUpdateWidgets, useWidgets } from 'modules/editMode/queries'
import { useEditModeWidgets, useOpenEditMode } from 'modules/editMode/store'
import { usePermissions } from 'modules/user/queries'
import { Block } from 'ui/Block'
import { Button, ButtonColors, ButtonSizes } from 'ui/Button'
import { Checkbox, CheckboxColors } from 'ui/Checkbox'

import classes from './EditModeMenu.module.scss'

interface EditModeMenuProps {
  className?: string
}

export const EditModeMenu: FC<EditModeMenuProps> = ({ className }) => {
  const [isDefault, setIsDefault] = useState(false)

  const translate = useTranslate()
  const closeEditMode = useOpenEditMode((state) => state.clear)
  const widgets = useEditModeWidgets((state) => state.widgets)
  const { activeWidgetsCount } = useUpdateWidgetsLocal()
  const { categoryId } = useWidgets(WidgetCategoryTypes.Forecast)
  const { updateWidgets, isLoadingUpdate } = useUpdateWidgets()
  const { permissions } = usePermissions()

  const onSave = () => {
    if (categoryId) {
      updateWidgets?.({
        data: {
          widgets: widgets.map((item) => ({ id: item.id, order: item.order })),
          categoryId,
          isDefault,
        },
        onSuccess: closeEditMode,
      })
    }
  }

  return (
    <Block className={cx(classes.wrap, className)}>
      <div className={classes.title}>{translate('editMode.menu.title')}</div>

      <div className={classes.top}>
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            color={ButtonColors.Primary}
            isRounded
            onClick={closeEditMode}
            size={ButtonSizes.Medium}
          >
            {translate('cancel')}
          </Button>
          <Button
            className={classes.button}
            color={ButtonColors.PrimaryWhite}
            disabled={!activeWidgetsCount}
            isRounded
            loading={isLoadingUpdate}
            onClick={onSave}
            size={ButtonSizes.Medium}
          >
            {translate('save')}
          </Button>
        </div>

        {permissions.canSetDefaultWidgetsOrder && (
          <Checkbox
            className={classes.checkbox}
            classNameLabel={classes.label}
            color={CheckboxColors.White}
            onChange={setIsDefault}
            value={isDefault}
          >
            {translate('editMode.menu.saveAsDefault')}
          </Checkbox>
        )}
      </div>

      <EditModeMenuWidgets />
    </Block>
  )
}
