import { FC } from 'react'

import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg'
import { ReactComponent as DragIcon } from 'assets/images/drag.svg'
import cx from 'clsx'
import { useTranslate } from 'config/i18n'
import { Widget } from 'modules/editMode/api'
import { EditModeChartWidget } from 'modules/editMode/components/EditModeChartWidget'
import { EditModeKPIWidget } from 'modules/editMode/components/EditModeKPIWidget'
import { EditModeRecWidget } from 'modules/editMode/components/EditModeRecWidget'
import { parseWidgetTitle } from 'modules/editMode/helpers/parseWidgetTitle'
import { WidgetTypes } from 'modules/editMode/interfaces'
import { useProject } from 'modules/projects/queries'
import { Block } from 'ui/Block'

import classes from './EditModeWidget.module.scss'

interface EditModeWidgetProps {
  className?: string
  item: Widget
  isDragging?: boolean
  onDragDown?: () => void
  onDelete?: (id: number) => void
}

export const EditModeWidget: FC<EditModeWidgetProps> = ({ className, item, isDragging, onDragDown, onDelete }) => {
  const { project } = useProject()

  const translate = useTranslate()

  return (
    <div className={cx(classes.wrap, className, { [classes.isDragging]: isDragging })}>
      <div className={classes.top}>
        <div className={classes.header}>
          <div className={classes.title}>{parseWidgetTitle(item.code)}</div>
          {item.code === WidgetTypes.Recommendations && !project?.cognitiveEnabled && (
            <div className={classes.subTitle}>{translate('editMode.recommendationsActivate')}</div>
          )}
        </div>
        <div className={classes.buttons}>
          <DeleteIcon className={classes.button} onClick={() => onDelete?.(item.id)} />
          <DragIcon className={classes.button} onMouseDown={onDragDown} />
        </div>
      </div>
      <Block className={classes.content}>
        {item?.code === WidgetTypes.Kpi && <EditModeKPIWidget />}
        {item?.code === WidgetTypes.Recommendations && <EditModeRecWidget />}
        {item?.code === WidgetTypes.Main && <EditModeChartWidget />}
        {item?.code === WidgetTypes.DemandDrivers && <EditModeChartWidget isBarChart />}
      </Block>
    </div>
  )
}
