import { FC, lazy as lazyImport } from 'react'
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom'

import { AuthLayout } from 'layouts/AuthLayout'
import { MainLayout } from 'layouts/MainLayout'
import { Loader, LoaderTypes } from 'ui/Loader'

import { twoFactorLoader } from './loaders/twoFactorLoader'
import { Routes } from './routes'

const lazy = (importFn: () => Promise<any>) =>
  lazyImport(() =>
    importFn().catch((error) => {
      if (!window.location.href.includes('error_import_module')) {
        const currentURL = window.location.href
        const separator = currentURL.includes('?') ? '&' : '?'
        window.location.href = currentURL + separator + 'error_import_module=1'
      }
      throw error
    }),
  )

const NotFoundPage = lazy(() => import('pages/NotFoundPage'))
const LoginPage = lazy(() => import('pages/LoginPage'))
const TwoFactorExpiredPage = lazy(() => import('pages/TwoFactorExpiredPage'))
const ResetPasswordPage = lazy(() => import('pages/ResetPassword'))
const PulsePage = lazy(() => import('pages/PulsePage'))
const DataSourcesPage = lazy(() => import('pages/DataSourcesPage'))
const ForecastPage = lazy(() => import('pages/ForecastPage'))
const ApprovalsPage = lazy(() => import('pages/ApprovalsPage'))
const MasterDataPage = lazy(() => import('pages/MasterDataPage'))
const MasterDataHierarchyPage = lazy(() => import('pages/MasterDataHierarchyPage'))
const StatusPage = lazy(() => import('pages/StatusPage'))
const InnovationManagementPage = lazy(() => import('pages/InnovationManagementPage'))
const PromoOptimizerPage = lazy(() => import('pages/PromoOptimizerPage'))
const CreatePromoScenarioPage = lazy(() => import('pages/CreatePromoScenarioPage'))
const PromoScenarioPage = lazy(() => import('pages/PromoScenarioPage'))
const PromoScenarioNotFoundPage = lazy(() => import('pages/PromoScenarioNotFoundPage'))
const ReplenScenariosPage = lazy(() => import('pages/ReplenScenariosPage'))
const CreateReplenScenarioPage = lazy(() => import('pages/CreateReplenScenarioPage'))
const ReplenScenarioPage = lazy(() => import('pages/ReplenScenarioPage'))
const ReplenWarehousesPage = lazy(() => import('pages/ReplenWarehousesPage'))
const ReplenOptimizerPage = lazy(() => import('pages/ReplenOptimizerPage'))
const ReplenOverviewPage = lazy(() => import('pages/ReplenOverviewPage'))
const OptimizerDemoPage = lazy(() => import('pages/OptimizerDemoPage'))
const CreateRtmScenarioPage = lazy(() => import('pages/CreateRtmScenarioPage'))
const RtmScenariosPage = lazy(() => import('pages/RtmScenariosPage'))
const RtmScenarioPage = lazy(() => import('pages/RtmScenarioPage'))
const RtmScenarioNotFoundPage = lazy(() => import('pages/RtmScenarioNotFoundPage'))
const CreateTetrisScenarioPage = lazy(() => import('pages/CreateTetrisScenarioPage'))
const TetrisScenariosPage = lazy(() => import('pages/TetrisScenariosPage'))
const TetrisScenarioPage = lazy(() => import('pages/TetrisScenarioPage'))
const TetrisScenarioNotFoundPage = lazy(() => import('pages/TetrisScenarioNotFoundPage'))
const CreateCfrScenarioPage = lazy(() => import('pages/CreateCfrScenarioPage'))
const CfrScenariosPage = lazy(() => import('pages/CfrScenariosPage'))
const CfrScenarioPage = lazy(() => import('pages/CfrScenarioPage'))
const CfrScenarioNotFoundPage = lazy(() => import('pages/CfrScenarioNotFoundPage'))
const PrgmCreatePage = lazy(() => import('pages/PrgmCreate'))
const SelectionsPage = lazy(() => import('pages/SelectionsPage'))
const SelectionPage = lazy(() => import('pages/SelectionPage'))
const NewSelectionPage = lazy(() => import('pages/NewSelectionPage'))

const routes: RouteObject[] = [
  {
    path: Routes.Login,
    element: <AuthLayout />,
    errorElement: <Loader type={LoaderTypes.SpinnerCenter} />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
    ],
  },
  {
    path: Routes.ResetPassword,
    element: <AuthLayout />,
    errorElement: <Loader type={LoaderTypes.SpinnerCenter} />,
    loader: () => {
      twoFactorLoader()
      return null
    },
    children: [
      {
        index: true,
        element: <ResetPasswordPage />,
      },
    ],
  },
  {
    path: Routes.TwoFactorExpired,
    element: <AuthLayout />,
    errorElement: <Loader type={LoaderTypes.SpinnerCenter} />,
    children: [
      {
        index: true,
        element: <TwoFactorExpiredPage />,
      },
    ],
  },
  {
    path: Routes.Main,
    element: <MainLayout />,
    errorElement: <MainLayout loading />,
    loader: () => {
      twoFactorLoader()
      return null
    },
    children: [
      {
        index: true,
        element: <PulsePage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
      {
        path: Routes.DataSources,
        element: <DataSourcesPage />,
      },
      {
        path: Routes.Forecast,
        element: <ForecastPage />,
      },
      {
        path: Routes.Approvals,
        element: <ApprovalsPage />,
      },
      {
        path: Routes.Status,
        element: <StatusPage />,
      },
      {
        path: Routes.InnovationManagement,
        element: <InnovationManagementPage />,
      },
      {
        path: Routes.MasterData,
        element: <MasterDataPage />,
      },
      {
        path: Routes.MasterDataHierarchy,
        element: <MasterDataHierarchyPage />,
      },
      {
        path: Routes.PromoOptimizer,
        element: <PromoOptimizerPage />,
      },
      {
        path: Routes.CreatePromoScenario,
        element: <CreatePromoScenarioPage />,
      },
      {
        path: Routes.PromoScenario,
        element: <PromoScenarioPage />,
      },
      {
        path: Routes.PromoScenarioNotFound,
        element: <PromoScenarioNotFoundPage />,
      },
      {
        path: Routes.ReplenScenarios,
        element: <ReplenScenariosPage />,
      },
      {
        path: Routes.CreateReplenScenario,
        element: <CreateReplenScenarioPage />,
      },
      {
        path: Routes.ReplenScenario,
        element: <ReplenScenarioPage />,
      },
      {
        path: Routes.ReplenScenarioNotFound,
        element: <div>ReplenScenarioNotFound</div>,
      },
      {
        path: Routes.ReplenWarehouses,
        element: <ReplenWarehousesPage />,
      },
      {
        path: Routes.ReplenOptimizer,
        element: <ReplenOptimizerPage />,
      },
      {
        path: Routes.ReplenOverview,
        element: <ReplenOverviewPage />,
      },
      {
        path: Routes.OptimizerDemo,
        element: <OptimizerDemoPage />,
      },
      {
        path: Routes.CreateRtmScenario,
        element: <CreateRtmScenarioPage />,
      },
      {
        path: Routes.RtmOptimizer,
        element: <RtmScenariosPage />,
      },
      {
        path: Routes.RtmScenario,
        element: <RtmScenarioPage />,
      },
      {
        path: Routes.RtmScenarioNotFound,
        element: <RtmScenarioNotFoundPage />,
      },
      {
        path: Routes.CreateTetrisScenario,
        element: <CreateTetrisScenarioPage />,
      },
      {
        path: Routes.TetrisOptimizer,
        element: <TetrisScenariosPage />,
      },
      {
        path: Routes.TetrisScenario,
        element: <TetrisScenarioPage />,
      },
      {
        path: Routes.TetrisScenarioNotFound,
        element: <TetrisScenarioNotFoundPage />,
      },
      {
        path: Routes.CreateCfrScenario,
        element: <CreateCfrScenarioPage />,
      },
      {
        path: Routes.CfrOptimizer,
        element: <CfrScenariosPage />,
      },
      {
        path: Routes.CfrScenario,
        element: <CfrScenarioPage />,
      },
      {
        path: Routes.CfrScenarioNotFound,
        element: <CfrScenarioNotFoundPage />,
      },
      {
        path: Routes.PrgmCreate,
        element: <PrgmCreatePage />,
      },
      {
        path: Routes.Selections,
        element: <SelectionsPage />,
      },
      {
        path: Routes.Selection,
        element: <SelectionPage />,
      },
      {
        path: Routes.NewSelection,
        element: <NewSelectionPage />,
      },
    ],
  },
]

export const RenderRoute: FC = () => (
  <RouterProvider fallbackElement={<Loader type={LoaderTypes.SpinnerCenter} />} router={createBrowserRouter(routes)} />
)
