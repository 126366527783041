export enum Permissions {
  addActualShipment = 'add_actualshipment',
  addPrice = 'add_price',
  deletePrice = 'delete_price',
  addTarget = 'add_target',
  deleteTarget = 'delete_target',
  addDataSourceVersion = 'add_datasourceversion',
  canViewDataStatuses = 'can_view_data_statuses',
  viewDataSources = 'view_datasource',
  deleteActualShipment = 'delete_actualshipment',
  deleteDataSourceVersion = 'delete_datasourceversion',
  sendForCalculationForecast = 'send_for_calculation_forecastscenarioresult',
  canDownloadForecastExcel = 'can_download_forecast_excel',
  viewForecastScenario = 'view_forecastscenario',
  viewForecastScenarioResult = 'view_forecastscenarioresult',
  setIsProposedForecast = 'set_is_proposed_forecastscenario',
  canEditMasterData = 'can_edit_masterdata',
  canDeletePromoScenario = 'delete_promoscenario',
  canChangePromoScenario = 'change_promoscenario',
  canDeleteReplenScenario = 'delete_replenscenario',
  canSetDefaultWidgetsOrder = 'can_set_default_widgets_order',
  canDeleteRtmScenario = 'delete_rtmscenario',
  canChangeRtmScenario = 'change_rtmscenario',
  canDeleteTetrisScenario = 'delete_tetrisscenario',
  canChangeTetrisScenario = 'change_tetrisscenario',
  canDeleteCfrScenario = 'delete_cfrscenario',
  canChangeCfrScenario = 'change_cfrscenario',
  viewEditForecastDashboard = 'view_edit_forecast_dashboard',
}
