import { FC, ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuthenticated } from 'modules/auth/handlers'
import { useBroadcastAuth } from 'modules/auth/handlers/useBroadcastAuth'
import { Routes } from 'routes/routes'

/**
 * Redirects on home page if user is logged in
 * @param Component Component
 * @returns Component
 */
export function withoutAuth<T = any>(Component: FC<T>) {
  return (props: JSX.IntrinsicAttributes & T): ReactElement => {
    const navigate = useNavigate()

    const { authenticated } = useAuthenticated()
    const { loading } = useBroadcastAuth()

    useEffect(() => {
      if (authenticated) {
        navigate(Routes.Main)
      }
    }, [authenticated])

    return <Component {...props} loading={loading} />
  }
}
