import { NoParamEndpointConstructor, TwoParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = '/scenarios'

interface Endpoints {
  updateWorkflow: TwoParamEndpointConstructor
  getActiveRequests: NoParamEndpointConstructor
  getFinishedRequests: NoParamEndpointConstructor
  getWorkflowIndicator: NoParamEndpointConstructor
}

export const workflowEndpoints: Endpoints = {
  updateWorkflow: (scenarioId, resultId) => `${baseUrl}/${scenarioId}/results/${resultId}/workflow`,
  getActiveRequests: () => '/workflow-processes/active',
  getFinishedRequests: () => '/workflow-processes/finished',
  getWorkflowIndicator: () => '/workflow-processes/active/indicator-check',
}
