import { FC, Suspense, useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-menu.svg'
import cx from 'clsx'
import { NavMenu } from 'components/template/NavMenu'
import { ProfileLinks } from 'components/template/ProfileLinks'
import { UnavailableBlock } from 'components/template/UnavailableBlock'
import { IS_NPT_ENV, IS_PRGM_ENV, SESSION_OVER_MS } from 'constants/env'
import { projectTypeMap } from 'constants/projects'
import { compose } from 'hocs'
import { withAuth } from 'hocs/withAuth'
import { useOpenMenu } from 'hooks/useOpenMenu'
import { useProjectType } from 'hooks/useProjectType'
import { useUnsavedChangesConfirm } from 'hooks/useUnsavedChangesConfirm'
import { ModalLayout } from 'layouts/ModalLayout'
import { EditMode } from 'modules/editMode/components/EditMode'
import { ProjectSelect } from 'modules/projects/components/ProjectSelect'
import { useProject } from 'modules/projects/queries'
import { SessionOver } from 'modules/session/components/SessionOver'
import { Routes } from 'routes/routes'
import { Loader, LoaderTypes } from 'ui/Loader'
import { Logo } from 'ui/Logo'

import classes from './MainLayout.module.scss'

interface MainLayoutProps {
  loading?: boolean
}

export const MainLayoutInternal: FC<MainLayoutProps> = ({ loading }) => {
  const [hoverMenu, setHoverMenu] = useState(false)
  const [animatedMenu, setAnimatedMenu] = useState(false)
  const [disabledEvents, setDisabledEvents] = useState(false)
  const openMenu = useOpenMenu((state) => state.open)
  const toggleMenu = useOpenMenu((state) => state.toggle)
  const { project } = useProject()
  const { handleReturnBack, isUnavailable } = useProjectType()

  useUnsavedChangesConfirm()

  useEffect(() => {
    setAnimatedMenu(!openMenu)
  }, [openMenu])

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (hoverMenu) {
      setDisabledEvents(true)
      interval = setTimeout(() => {
        setDisabledEvents(false)
      }, 500)
    }
    return () => {
      clearTimeout(interval)
    }
  }, [hoverMenu])

  return (
    <ModalLayout>
      <section
        className={cx(classes.wrap, { [classes.open]: openMenu, [classes.animatedMenu]: animatedMenu && !openMenu })}
        data-container=""
      >
        {!!SESSION_OVER_MS && <SessionOver />}
        {openMenu && <ProfileLinks />}

        <aside
          className={cx(classes.sidebar, {
            [classes.hoverMenu]: hoverMenu,
            [classes.disabledEvents]: disabledEvents,
          })}
          onMouseEnter={() => setHoverMenu(!openMenu)}
          onMouseLeave={() => setHoverMenu(false)}
        >
          <div className={classes.openMenuCont}>
            <div className={classes.openMenu} onClick={toggleMenu}>
              <ArrowIcon className="svg-img" />
            </div>
          </div>
          <Link to={(project && (projectTypeMap[project?.type.code] as unknown as string)) || Routes.Main}>
            <Logo className={classes.logo} />
          </Link>
          {!IS_PRGM_ENV && !IS_NPT_ENV && <ProjectSelect className={classes.projectSwitch} />}
          <NavMenu className={classes.menu} />

          {!openMenu && <ProfileLinks extended />}
        </aside>
        <div
          className={classes.openSidebar}
          onMouseEnter={() => setHoverMenu(!openMenu)}
          onMouseLeave={() => setHoverMenu(false)}
        />

        <main className={classes.main}>
          <div className={classes.content}>
            <Suspense fallback={<Loader className={classes.loader} type={LoaderTypes.SpinnerCenter} />}>
              {loading ? (
                <Loader className={classes.loader} type={LoaderTypes.SpinnerCenter} />
              ) : isUnavailable ? (
                <UnavailableBlock onClick={handleReturnBack} />
              ) : (
                <div className={classes.inside}>
                  <Outlet />
                </div>
              )}
            </Suspense>
          </div>
          <EditMode />
        </main>
      </section>
    </ModalLayout>
  )
}

export const MainLayout = compose(MainLayoutInternal, withAuth)
