import { useCallback } from 'react'
import { useBeforeUnload, unstable_usePrompt as usePrompt } from 'react-router-dom'

import { useTranslate } from 'config/i18n'
import { useUnsavedChanges } from 'hooks/useUnsavedChanges'

export const useUnsavedChangesConfirm = () => {
  const translate = useTranslate()

  const unsavedChanges = useUnsavedChanges((state) => state.unsavedChanges)
  const unsavedItem = unsavedChanges.find((item) => item.isDirty)
  const isDirty = !!unsavedItem
  const message = unsavedItem?.message || translate('unsavedChangesWarning')

  useBeforeUnload(
    useCallback(
      (event) => {
        if (isDirty) {
          event.preventDefault()
          event.returnValue = message
          return message
        }
      },
      [isDirty],
    ),
  )

  usePrompt({ when: isDirty, message })
}
