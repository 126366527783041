import { FC, useState } from 'react'
import { generatePath } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-bottom.svg'
import cx from 'clsx'
import { NavLink } from 'components/template/NavLink'
import { NavTitle } from 'components/template/NavTitle'
import { useTranslate } from 'config/i18n'
import { IS_DEMO_OPTIMIZE_ENABLED, IS_NPT_ENV, IS_PRGM_ENV, IS_WORKFLOW_ENABLED } from 'constants/env'
import { ProjectTypes } from 'constants/projects'
import { useProject } from 'modules/projects/queries'
import { useProfile } from 'modules/user/queries'
import { useWFIndicator } from 'modules/workflow/queries/useWFIndicator'
import { Routes } from 'routes/routes'
import { NavMenuNptDemand } from 'streams/demand/navMenu/NavMenuNptDemand'
import { SlideToggle, SlideToggleTypes } from 'ui/SlideToggle'

import classes from './NavMenu.module.scss'

interface NavMenuProps {
  className?: string
}

export const NavMenu: FC<NavMenuProps> = ({ className }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const { project, isPoc } = useProject()
  const { profile } = useProfile()
  const translate = useTranslate()

  const { data: isNewWFActions } = useWFIndicator()

  const toggleMenuOpen = () => setIsSubMenuOpen((prev) => !prev)

  return (
    <nav className={cx(classes.wrap, className)}>
      {IS_NPT_ENV ? (
        <NavMenuNptDemand />
      ) : (
        <>
          {project?.type.code === ProjectTypes.Forecast && (
            <>
              {!isPoc && (
                <NavLink className={classes.menuItem} path={Routes.Main}>
                  {translate('menu.main')}
                </NavLink>
              )}

              <NavTitle
                className={cx(classes.menuItem, classes.disabled)}
                icon={<ArrowIcon />}
                isActive={isSubMenuOpen}
                onClick={toggleMenuOpen}
              >
                {translate('menu.data')}
              </NavTitle>

              <SlideToggle classNameWrap={classes.subMenu} open={isSubMenuOpen} type={SlideToggleTypes.NoTransform}>
                <NavLink className={classes.menuItem} path={Routes.DataSources}>
                  {translate('menu.dataSources')}
                </NavLink>
                <NavLink className={classes.menuItem} path={Routes.MasterData}>
                  {translate('menu.masterData')}
                </NavLink>

                {!isPoc && (
                  <>
                    <NavLink className={classes.menuItem} path={Routes.Status}>
                      {translate('menu.status')}
                    </NavLink>
                    <NavLink className={classes.menuItem} path={Routes.InnovationManagement}>
                      {translate('menu.innovationManagement')}
                    </NavLink>
                  </>
                )}
              </SlideToggle>

              {profile?.activeProjectId && (
                <NavLink
                  className={classes.menuItem}
                  path={generatePath(Routes.Forecast, { projectId: String(profile.activeProjectId) })}
                >
                  {translate('menu.forecast')}
                </NavLink>
              )}

              {IS_DEMO_OPTIMIZE_ENABLED && <NavLink path={Routes.OptimizerDemo}>{translate('menu.promo')}</NavLink>}

              {profile?.activeProjectId && IS_WORKFLOW_ENABLED && (
                <NavLink className={classes.secondMenu} path={Routes.Approvals}>
                  {translate('menu.approvals')}
                  {isNewWFActions && <span className={classes.newActions} />}
                </NavLink>
              )}
            </>
          )}

          {project?.type.code === ProjectTypes.PromoOptimizer && (
            <NavLink path={Routes.PromoOptimizer}>{translate('menu.allScenarios')}</NavLink>
          )}

          {project?.type.code === ProjectTypes.ReplenOptimizer && (
            <>
              <NavLink className={classes.menuItem} path={Routes.ReplenWarehouses}>
                {translate('menu.warehouses')}
              </NavLink>
              <NavLink className={classes.menuItem} path={Routes.ReplenScenarios}>
                {translate('menu.scenarios')}
              </NavLink>
            </>
          )}

          {project?.type.code === ProjectTypes.RtmOptimizer && (
            <NavLink path={Routes.RtmOptimizer}>{translate('menu.allScenarios')}</NavLink>
          )}

          {project?.type.code === ProjectTypes.TetrisOptimizer && (
            <NavLink path={Routes.TetrisOptimizer}>{translate('menu.allScenarios')}</NavLink>
          )}

          {project?.type.code === ProjectTypes.CfrOptimizer && (
            <NavLink path={Routes.CfrOptimizer}>{translate('menu.allScenarios')}</NavLink>
          )}
        </>
      )}
      {IS_PRGM_ENV && <NavLink path={Routes.PrgmCreate}>Create</NavLink>}
    </nav>
  )
}
