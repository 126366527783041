import { redirect } from 'react-router-dom'

import { setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'
import { BrowserHistory } from 'history'
import { authApi } from 'modules/auth/api'
import { useResetPasswordTokens } from 'modules/resetPassword/store'
import { Routes } from 'routes/routes'

export const twoFactorLoader = async (history?: BrowserHistory) => {
  const search = history ? history.location.search : window.location.search
  const params = new URLSearchParams(search)
  const access = params.get('access')
  const refresh = params.get('refresh')
  const isTwoFactor = params.get('two_factor')
  const store = useResetPasswordTokens.getState()

  if (isTwoFactor) {
    if (access && refresh) {
      try {
        setAxiosAuthorizationToken(access)
        await authApi.checkTwoFactorCode()
        storage.setToken(access, store.remember)
        storage.setRefresh(refresh, store.remember)
      } catch (e) {
        store.setAccess(access)
        store.setRefresh(refresh)
        setAxiosAuthorizationToken(access)
        if (history) {
          history.push(Routes.TwoFactorExpired)
        } else {
          redirect(Routes.TwoFactorExpired)
        }
      }
    } else {
      if (history) {
        history.push(Routes.Login)
      } else {
        redirect(Routes.Login)
      }
    }
  }
}
