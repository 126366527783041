import { ICQueries } from 'modules/innovationCalculator/queries/types'
import { IMQueries } from 'modules/innovationManagement/queries/types'
import { PMQueries } from 'modules/promoManagement/queries/types'
import { useQueryClient } from 'packages/react-query'

export const useClearInnovationDashboard = () => {
  const queryClient = useQueryClient()

  const clearInnovationDashboard = () => {
    queryClient.removeQueries({ queryKey: [IMQueries.IMTable] })
    queryClient.removeQueries({ queryKey: [PMQueries.PMTable] })
    queryClient.removeQueries({ queryKey: [ICQueries.ICTable] })
    queryClient.removeQueries({ queryKey: [ICQueries.SearchICElement] })
  }

  return { clearInnovationDashboard }
}
