export const keySetsRu = [
  () => import('./common.json'),
  () => import('./titles.json'),
  () => import('./menu.json'),
  () => import('./auth.json'),
  () => import('./dataSources.json'),
  () => import('./forecast.json'),
  () => import('./projects.json'),
  () => import('./blockA.json'),
  () => import('./blockB.json'),
  () => import('./charts.json'),
  () => import('./tables.json'),
  () => import('./kpi.json'),
  () => import('./pulse.json'),
  () => import('./statusDashboard.json'),
  () => import('./masterData.json'),
  () => import('./promoScenario.json'),
  () => import('./promoScenarioOutput.json'),
  () => import('./apiErrors.json'),
  () => import('./validationErrors.json'),
  () => import('./errorsNoData.json'),
  () => import('./errors.json'),
  () => import('./delistingTable.json'),
  () => import('./innovationTable.json'),
  () => import('./promoTable.json'),
  () => import('./statusTable.json'),
  () => import('./transitionTable.json'),
  () => import('./promoDataSource.json'),
  () => import('./innovationDashboard.json'),
  () => import('./recommendations.json'),
  () => import('./innovationCalculator.json'),
  () => import('./wsErrors.json'),
  () => import('./editMode.json'),
  () => import('./workflow.json'),
  () => import('./replenScenario.json'),
  () => import('./rtmScenario.json'),
  () => import('./rtmDataSource.json'),
  () => import('./tetrisScenario.json'),
  () => import('./tetrisDataSource.json'),
  () => import('./scenarioShared.json'),
  () => import('./cfrScenario.json'),
  () => import('./cfrDataSource.json'),
  () => import('./cfrScenarioOutput.json'),
  () => import('./prgmCreate.json'),
  () => import('./selections.json'),
  () => import('./siInsights.json'),
  () => import('./uploadCard.json'),
]
