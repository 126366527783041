import { FC, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import cx from 'clsx'
import { Widget } from 'modules/editMode/api'
import { EditModeWidget } from 'modules/editMode/components/EditModeWidget/EditModeWidget'
import { useUpdateWidgetsLocal } from 'modules/editMode/handlers'
import { useWidgetsFiltered } from 'modules/editMode/handlers/useWidgetsFiltered'
import { DragAreaName, DraggableItem, DraggableItemSizes } from 'modules/editMode/interfaces'

import classes from './EditModeWidgetBlock.module.scss'

interface EditModeWidgetBlockProps {
  className?: string
  item: Widget
}

export const EditModeWidgetBlock: FC<EditModeWidgetBlockProps> = ({ className, item }) => {
  const [canDragging, setCanDragging] = useState(false)
  const dragRef = useRef<HTMLDivElement>(null)

  const { widgetsFiltered } = useWidgetsFiltered()

  const { deleteWidget, findWidget, changeWidgetOrderInternal, applyWidgets } = useUpdateWidgetsLocal()

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: canDragging ? DragAreaName.Forecast : '',
      item: { ...item, size: DraggableItemSizes.Big, width: dragRef.current?.clientWidth },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (_, monitor) => {
        if (monitor.didDrop()) {
          applyWidgets?.()
        }
        setCanDragging(false)
      },
    }),
    [canDragging, item.id, widgetsFiltered],
  )

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DragAreaName.Forecast,
      hover: (elem: DraggableItem) => {
        const foundWidget = findWidget?.(item.id)
        changeWidgetOrderInternal?.(elem.id, foundWidget.widget)
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [findWidget, item],
  )

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [canDragging])

  return (
    <div className={cx(classes.wrap, className)} ref={(node) => drag(drop(node))}>
      <div ref={dragRef}>
        <EditModeWidget
          className={cx({ [classes.isDragging]: isDragging })}
          item={item}
          onDelete={deleteWidget}
          onDragDown={() => setCanDragging(true)}
        />
        <div className={cx(classes.line, { [classes.showLine]: isOver })} />
      </div>
    </div>
  )
}
