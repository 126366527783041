import { FormEvent, HTMLInputTypeAttribute, KeyboardEvent, ReactNode } from 'react'

export type ButtonHTMLTypes = 'submit' | 'reset' | 'button' | undefined
export type DateFormat = Date | number | string | null

export interface InputProps {
  className?: string
  classNameAfter?: string
  classNameContainer?: string
  classNameFocus?: string
  name?: string
  value?: string | number | null
  onChange?: (value: string) => void
  onFocus?: (event: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) => void
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLTextAreaElement>) => void
  autoComplete?: 'on' | 'off'
  placeholder?: string
  mask?: string
  htmlType?: HTMLInputTypeAttribute
  type?: TextFieldTypes
  size?: TextFieldSizes
  color?: TextFieldColors
  disabled?: boolean
  loading?: boolean
  maxLength?: number
  multiline?: boolean
  autoResize?: boolean
  autoFocus?: boolean
  maxRows?: number
  noWrap?: boolean
  afterContent?: ReactNode
  onClickAfter?: () => void
  afterIsClickable?: boolean
  isCell?: boolean
  isError?: boolean
  noEditable?: boolean
  isChanged?: boolean
  isCleanable?: boolean
}

export enum TextFieldColors {
  Default = 'default',
  Primary = 'primary',
  Danger = 'danger',
}

export enum TextFieldTypes {
  Default = 'default',
  Rounded = 'rounded',
}

export enum TextFieldSizes {
  Default = 'default',
  Large = 'large',
}

export interface TabObject {
  id?: number
  title: ReactNode
  active?: boolean
  code?: string
  disabled?: boolean
  error?: boolean
}

export interface OptionTreeItem<Meta = unknown> {
  label: ReactNode
  value?: string | number | null
  items?: OptionTreeItem[]
  code?: string
  childrenCount?: number
  getNextPage?: () => void
  hasNextPage?: boolean
  meta?: Meta
  parentValue?: string | number | null
  hierarchyId?: number
  parentIds?: number[]
  highlighted?: boolean
  className?: string
}

export interface FilterItem {
  label: string
  name: string
  options?: OptionTreeItem[]
  isTree?: boolean
  multiple?: boolean
  showSearch?: boolean
  defaultValue?: string | number | (string | number)[]
  defaultIsReset?: boolean
  id?: number
}

export enum DataSourceUploadTypes {
  ManualFile = 'manualFile',
  Scenario = 'scenario',
}

export enum DataSourceUploadExtendedTypes {
  ManualFile = 'manualFile',
  Scenario = 'scenario',
  NotDownload = 'notDownload',
}

export enum DataSourceUploadingStatuses {
  DataSuccessfullyUploaded = 'Data successfully uploaded',
  SeeLogFileForDetails = 'File format error. See log file for details',
}
