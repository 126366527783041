import { axios } from 'config/axios'
import { workflowDashboardPerPage } from 'constants/pagination'
import { GetAllData, GetAllResponse, GetOneResponse, Pagination } from 'interfaces/api.interfaces'
import { deleteEmpty, deleteNullable } from 'packages/helper'
import { defaultPagination } from 'utils/pagination'

import { workflowEndpoints } from './endpoints'
import { UpdateWorkflowData, WorkflowIndicator, WorkflowTableRow } from './interfaces'

const updateWorkflow = async (scenarioId: number, resultId: number, data: UpdateWorkflowData): Promise<void> => {
  await axios.request({
    method: 'PATCH',
    url: workflowEndpoints.updateWorkflow(scenarioId, resultId),
    data: deleteEmpty(data),
  })
}

const getActiveRequests = async (pagination?: Pagination): GetAllResponse<WorkflowTableRow> => {
  const { data } = await axios.request<GetAllData<WorkflowTableRow>>({
    method: 'GET',
    url: workflowEndpoints.getActiveRequests(),
    params: defaultPagination(pagination, workflowDashboardPerPage),
  })
  return data
}

const getFinishedRequests = async (pagination?: Pagination): GetAllResponse<WorkflowTableRow> => {
  const { data } = await axios.request<GetAllData<WorkflowTableRow>>({
    method: 'GET',
    url: workflowEndpoints.getFinishedRequests(),
    params: defaultPagination(pagination, workflowDashboardPerPage),
  })
  return data
}

const getWorkflowIndicator = async (date: string | null): GetOneResponse<WorkflowIndicator> => {
  const { data } = await axios.request<WorkflowIndicator>({
    method: 'GET',
    url: workflowEndpoints.getWorkflowIndicator(),
    params: deleteNullable({ date }),
  })
  return data
}

export const workflowApi = {
  updateWorkflow,
  getActiveRequests,
  getFinishedRequests,
  getWorkflowIndicator,
}
