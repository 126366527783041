import { devtools } from 'config/devtools'
import { create } from 'zustand'

export interface UnsavedItem {
  code: string
  isDirty: boolean
  message?: string
}

export interface UseUnsavedChanges {
  unsavedChanges: UnsavedItem[]
  addUnsavedItem: (item: UnsavedItem) => void
  removeUnsavedItem: (code: string) => void
  setUnsavedItem: (item: UnsavedItem) => void
  clear: () => void
}

export const useUnsavedChanges = create<UseUnsavedChanges>()(
  devtools(
    (set) => ({
      unsavedChanges: [],
      addUnsavedItem: (item) =>
        set(({ unsavedChanges }) => {
          if (unsavedChanges.find((unsavedItem) => unsavedItem.code === item.code)) {
            return { unsavedChanges }
          }
          return { unsavedChanges: [...unsavedChanges, item] }
        }),
      removeUnsavedItem: (code) =>
        set(({ unsavedChanges }) => ({
          unsavedChanges: unsavedChanges.filter((unsavedItem) => unsavedItem.code !== code),
        })),
      setUnsavedItem: (item) =>
        set(({ unsavedChanges }) => ({
          unsavedChanges: unsavedChanges.map((unsavedItem) => {
            if (item.code === unsavedItem.code) {
              return item
            }
            return unsavedItem
          }),
        })),
      clear: () => set({ unsavedChanges: [] }),
    }),
    {
      store: 'unsavedChanges',
    },
  ),
)
