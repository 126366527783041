import { DelistingQueries } from 'modules/delistingTable/queries/types'
import { InnovationQueries } from 'modules/innovationTable/queries/types'
import { PromoQueries } from 'modules/promoTable/queries/types'
import { StatusDashboardQueries } from 'modules/statusDashboard/queries/types'
import { StatusQueries } from 'modules/statusTable/queries/types'
import { TransitionQueries } from 'modules/transitionTable/queries/types'
import { useQueryClient } from 'packages/react-query'

export const useClearCashStatusDashboard = () => {
  const queryClient = useQueryClient()

  const clearCashStatusDashboard = () => {
    queryClient.removeQueries({ queryKey: [StatusDashboardQueries.StatusDashboard] })
    queryClient.removeQueries({ queryKey: [StatusQueries.StatusTable] })
    queryClient.removeQueries({ queryKey: [DelistingQueries.DelistingTable] })
    queryClient.removeQueries({ queryKey: [InnovationQueries.InnovationTable] })
    queryClient.removeQueries({ queryKey: [PromoQueries.PromoTable] })
    queryClient.removeQueries({ queryKey: [TransitionQueries.TransitionTable] })
    queryClient.removeQueries({ queryKey: [DelistingQueries.SearchDelistingElement] })
    queryClient.removeQueries({ queryKey: [InnovationQueries.SearchInnovationChangeDummy] })
    queryClient.removeQueries({ queryKey: [PromoQueries.SearchPromoChangeDummy] })
    queryClient.removeQueries({ queryKey: [TransitionQueries.SearchTransitionElementNew] })
    queryClient.removeQueries({ queryKey: [TransitionQueries.SearchTransitionElementOld] })
  }

  return { clearCashStatusDashboard }
}
