import { useCallback, useEffect, useState } from 'react'

import storage from 'config/storage'
import { IS_JUME_ENV } from 'constants/env'
import { useLogoutClear } from 'hooks/useLogoutClear'

enum MessageEvents {
  TokensRequest = 'tokens_request',
  TokensReceive = 'tokens_receive',
  Logout = 'logout',
}

type MessageEvent = {
  data:
    | {
        type: MessageEvents.TokensRequest
      }
    | {
        type: MessageEvents.TokensReceive
        tokens?: {
          access: string
          refresh: string
        }
      }
    | {
        type: MessageEvents.Logout
      }
}

export const useBroadcastAuth = () => {
  const [loading, setLoading] = useState(false)
  const [broadcastChannel, setBroadcastChannel] = useState<BroadcastChannel | null>()
  const { logoutClear } = useLogoutClear()

  const broadcastLogout = useCallback(() => {
    if (!IS_JUME_ENV) {
      return
    }
    broadcastChannel?.postMessage({
      type: MessageEvents.Logout,
    })
  }, [broadcastChannel])

  useEffect(() => {
    if (!IS_JUME_ENV) {
      return
    }
    const newBroadcastChannel = new BroadcastChannel('auth')
    setBroadcastChannel(newBroadcastChannel)
    let timer: NodeJS.Timeout | undefined

    const token = storage.getToken()
    if (!token) {
      setLoading(true)
      newBroadcastChannel.postMessage({
        type: MessageEvents.TokensRequest,
      })
      timer = setTimeout(() => {
        setLoading(false)
      }, 1000)
    }

    newBroadcastChannel.onmessage = (event: MessageEvent) => {
      switch (event.data.type) {
        case MessageEvents.TokensRequest:
          const access = storage.getToken()
          const refresh = storage.getRefresh()
          const tokens =
            access && refresh
              ? {
                  access,
                  refresh,
                }
              : undefined
          newBroadcastChannel.postMessage({
            type: MessageEvents.TokensReceive,
            tokens,
          })
          break
        case MessageEvents.TokensReceive:
          if (document.visibilityState !== 'visible') {
            return
          }
          clearTimeout(timer)
          setLoading(false)
          if (event.data.tokens) {
            storage.setToken(event.data.tokens.access, false)
            storage.setRefresh(event.data.tokens.refresh, false)
          }
          break

        case MessageEvents.Logout:
          if (document.visibilityState === 'visible') {
            return
          }
          logoutClear()
          break
      }
    }
    return () => {
      if (!broadcastChannel) {
        return
      }
      broadcastChannel.onmessage = null
    }
  }, [])

  return {
    loading,
    broadcastLogout,
  }
}
