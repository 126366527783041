import { NoParamEndpointConstructor, OneParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = '/projects'

interface Endpoints {
  getProject: OneParamEndpointConstructor
  getProjects: NoParamEndpointConstructor
  getProjectInfo: OneParamEndpointConstructor
}

export const projectsEndpoints: Endpoints = {
  getProject: (projectId) => `${baseUrl}/${projectId}`,
  getProjects: () => baseUrl,
  getProjectInfo: (projectId) => `${baseUrl}/${projectId}/info`,
}
