import { FC, Suspense } from 'react'
import { Link, Outlet } from 'react-router-dom'

import { withoutAuth } from 'hocs/withoutAuth'
import { Routes } from 'routes/routes'
import { Loader, LoaderTypes } from 'ui/Loader'
import { Logo } from 'ui/Logo'

import classes from './AuthLayout.module.scss'

interface AuthLayoutProps {
  loading?: boolean
}

const AuthLayoutInternal: FC<AuthLayoutProps> = ({ loading }) => (
  <section className={classes.wrap}>
    <aside className={classes.sidebar}>
      <Link to={Routes.Main}>
        <Logo className={classes.logo} />
      </Link>
    </aside>
    <main className={classes.main}>
      <div className={classes.content}>
        <Suspense fallback={<Loader className={classes.loader} type={LoaderTypes.SpinnerCenter} />}>
          {loading ? <Loader className={classes.loader} type={LoaderTypes.SpinnerCenter} /> : <Outlet />}
        </Suspense>
      </div>
    </main>
  </section>
)

export const AuthLayout = withoutAuth(AuthLayoutInternal)
