import { FC, MouseEventHandler } from 'react'

import { ReactComponent as CloseMiniIcon } from 'assets/images/close-mini.svg'
import { ReactComponent as CloseIcon } from 'assets/images/close.svg'
import cx from 'clsx'

import classes from './Close.module.scss'

export enum CloseSizes {
  Default = 'default',
  Small = 'small',
}

interface CloseProps {
  className?: string
  onClick?: () => void
  size?: CloseSizes
  stopPropagation?: boolean
  dataStopPropagation?: boolean
}

export const Close: FC<CloseProps> = ({
  className,
  onClick,
  size = CloseSizes.Default,
  stopPropagation,
  dataStopPropagation,
}) => {
  const onClickInternal: MouseEventHandler = (e) => {
    if (stopPropagation) {
      e.stopPropagation()
    }
    if (onClick) {
      onClick()
    }
  }

  return (
    <div
      className={cx(classes.close, className, classes[size])}
      data-stop-propagation={dataStopPropagation}
      onClick={onClickInternal}
    >
      {size === CloseSizes.Small ? <CloseMiniIcon /> : <CloseIcon />}
    </div>
  )
}
