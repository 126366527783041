import { remove } from 'lodash'
import { WidgetTypes } from 'modules/editMode/interfaces'
import { useEditModeWidgets } from 'modules/editMode/store'
import { useProject } from 'modules/projects/queries'
import { usePermissions } from 'modules/user/queries'

export const useWidgetsFiltered = () => {
  const { isPoc } = useProject()
  const { permissions } = usePermissions()
  const widgets = useEditModeWidgets((state) => state.widgets)

  const widgetsFiltered = [...widgets]

  if (isPoc) {
    remove(widgetsFiltered, (item) => item.code && [WidgetTypes.Kpi, WidgetTypes.Recommendations].includes(item.code))
  }
  if (!permissions.viewEditForecastDashboard) {
    remove(widgetsFiltered, (item) => item.code === WidgetTypes.Main)
  }

  return { widgetsFiltered }
}
