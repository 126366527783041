export const storageAuthName = 'auth'
export const storageTokenName = 'token'
export const storageRefreshName = 'refresh'
export const storageOptimizeUrl = 'optimizeUrl'
export const storageLang = 'lang'
export const storageVisibilityForecastElements = 'visibilityForecastElements'
export const storageProjectType = 'projectType'
export const getStoragePromoScenarioPFRName = (id: number) => `promoScenarioPFR_${id}`
export const getStorageRtmScenarioPFRName = (id: number) => `rtmScenarioPFR_${id}`
export const getStorageTetrisScenarioPFRName = (id: number) => `tetrisScenarioPFR_${id}`
export const getStorageCfrScenarioPFRName = (id: number) => `cfrScenarioPFR_${id}`
