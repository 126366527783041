import { FC, MouseEventHandler, ReactNode } from 'react'

import { ReactComponent as UpdateIcon } from 'assets/images/update.svg'
import cx from 'clsx'
import { Button, ButtonColors, ButtonSizes } from 'ui/Button'
import { Close, CloseSizes } from 'ui/Close'
import { isNotStopPropagation } from 'utils/isNotStopPropagation'

import classes from './Tag.module.scss'

export enum TagSizes {
  Default = 'default',
  Medium = 'medium',
  Small = 'small',
  Large = 'large',
}

export enum TagColors {
  Default = 'default',
  Danger = 'danger',
  White = 'white',
  Dark = 'dark',
  Black = 'black',
  Primary = 'primary',
  Red = 'red',
  Transparent = 'transparent',
}

interface TagProps {
  className?: string
  classNameDisabled?: string
  children?: ReactNode
  icon?: ReactNode
  isClosable?: boolean
  isClickable?: boolean
  color?: TagColors
  size?: TagSizes
  colorHex?: string
  isBorder?: boolean
  onClick?: () => void
  onClose?: () => void
  disabled?: boolean
  onRepeat?: () => void
  isRepeatable?: boolean
  flex?: boolean
}

export const Tag: FC<TagProps> = ({
  className,
  classNameDisabled,
  children,
  icon,
  isClosable,
  isClickable,
  color = TagColors.Default,
  size = TagSizes.Default,
  colorHex,
  isBorder,
  onClick,
  onClose,
  disabled,
  onRepeat,
  isRepeatable,
  flex,
}) => {
  const onClickInternal: MouseEventHandler = (e) => {
    if (isNotStopPropagation(e.target) && onClick) {
      onClick()
    }
  }

  const onCloseInternal = () => {
    if (onClose) {
      onClose()
    }
  }

  const onRepeatInternal: MouseEventHandler = (e) => {
    e.stopPropagation()
    onRepeat?.()
  }

  return (
    <div
      className={cx(classes.wrap, className, color && classes[color], classes[size], disabled && classNameDisabled, {
        [classes.pointer]: isClickable,
        [classes.disabled]: disabled,
        [classes.isBorder]: isBorder,
      })}
      onClick={onClickInternal}
      style={
        isBorder
          ? { boxShadow: `0 0 0 1px ${colorHex} inset`, color: colorHex }
          : { backgroundColor: colorHex, color: colorHex ? '#fff' : undefined }
      }
    >
      <span
        className={cx(classes.cont, {
          [classes.contShort]: isClosable,
        })}
      >
        {icon && <div className={classes.icon}>{icon}</div>}
        <span className={cx(classes.label, { [classes.flex]: flex })}>{children}</span>
      </span>

      {isRepeatable && (
        <Button
          className={classes.repeatButton}
          color={ButtonColors.Text}
          onClick={onRepeatInternal}
          size={ButtonSizes.Small}
        >
          <UpdateIcon className={classes.repeatIcon} />
        </Button>
      )}

      {isClosable && (
        <Close className={classes.icoClose} dataStopPropagation onClick={onCloseInternal} size={CloseSizes.Small} />
      )}
    </div>
  )
}
