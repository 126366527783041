import { NoParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = '/account'
const personalInfoUrl = '/personal-info'

interface Endpoints {
  getInfo: NoParamEndpointConstructor
  getPermissions: NoParamEndpointConstructor
  updateInfo: NoParamEndpointConstructor
}

export const userEndpoints: Endpoints = {
  getInfo: () => `${baseUrl}${personalInfoUrl}`,
  getPermissions: () => `${baseUrl}/permissions`,
  updateInfo: () => `${baseUrl}${personalInfoUrl}`,
}
