import { FC, ReactNode, RefObject, useEffect, useRef } from 'react'

import { useClickAway, useEventListener, useSafeState } from 'ahooks'
import cx from 'clsx'
import { Portal } from 'components/template/Portal'
import { useIsMountedMs } from 'hooks/useIsMountedMs'
import { toArray } from 'packages/helper'
import { Animation, AnimationTypes } from 'ui/Animation'
import { useWindowSize } from 'usehooks-ts'
import { renderElement } from 'utils/renderElement'
import { renderTicks } from 'utils/renderTicks'

import classes from './Dropdown.module.scss'
import { parseTypeAnimation } from './parseTypeAnimation'

interface DropdownProps {
  refWrap?: RefObject<HTMLDivElement>
  refClickAway?: RefObject<HTMLDivElement> | RefObject<HTMLDivElement>[]
  getPopupContainer?: () => HTMLElement | null
  open?: boolean
  setOpen?: (open: boolean) => void
  disableClickAway?: (event: Event) => boolean
  children?: ReactNode
  classNamePortal?: string
  className?: string
  offsetY?: number
  offsetX?: number
  onClickAway?: (event: Event) => void
  maxOffsetTop?: number | undefined
  typeAnimation?: 'scaleTo' | AnimationTypes
  position?: 'auto' | 'top' | 'topLeft' | 'topRight' | 'bottom' | 'bottomLeft' | 'bottomRight'
  hideOnWheel?: boolean
}

export const Dropdown: FC<DropdownProps> = ({
  refWrap,
  refClickAway,
  getPopupContainer,
  open,
  setOpen,
  disableClickAway,
  children,
  classNamePortal,
  className,
  offsetY = 10,
  offsetX = 0,
  onClickAway,
  maxOffsetTop,
  typeAnimation = 'scaleTo',
  position = 'auto',
  hideOnWheel = true,
}) => {
  const [isMounted] = useIsMountedMs(open)
  const [isReady, setIsReady] = useSafeState(false)
  const [isRenderForPosition, setIsRenderForPosition] = useSafeState(false)
  const [isTop, setIsTop] = useSafeState(false)
  const [positionWindow, setPositionWindow] = useSafeState({ top: 0, left: 0 })
  const refDropdown = useRef<HTMLDivElement>(null)
  const sizeWindow = useWindowSize()

  const getPopupContainerInternal = (): RefObject<HTMLElement> => {
    const container: HTMLElement = document.querySelector('[data-container]') ?? document.body
    if (getPopupContainer) {
      return { current: getPopupContainer() ?? container }
    }
    return { current: container }
  }

  const onVisibleChange = async (newOpen: boolean) => {
    if (!refWrap) {
      return
    }
    setOpen?.(newOpen)
    if (newOpen) {
      const rectWrap = refWrap.current?.getBoundingClientRect()
      const rectContainer = getPopupContainerInternal().current?.getBoundingClientRect()
      if (rectWrap && rectContainer) {
        setIsRenderForPosition(true)
        await renderElement(refDropdown)
        const height = refDropdown.current?.getBoundingClientRect().height || 300
        const topContainer = rectContainer.top > 0 ? rectContainer.top : 0
        const offsetTop =
          typeof maxOffsetTop === 'number' && rectWrap.height + offsetY > maxOffsetTop
            ? maxOffsetTop
            : rectWrap.height + offsetY
        const top = rectWrap.top - topContainer + offsetTop + 10
        const newIsTop =
          ['top', 'topLeft', 'topRight'].includes(position) || (window.innerHeight - top < height && top > height)
        const isCenter = ['top', 'bottom'].includes(position)
        const isRight = ['topRight', 'bottomRight'].includes(position)
        setPositionWindow({
          top: rectWrap.top - rectContainer.top + (newIsTop ? -offsetY : offsetTop),
          left:
            rectWrap.left -
            rectContainer.left +
            offsetX +
            (isCenter ? rectWrap.width / 2 : isRight ? rectWrap.width : 0),
        })
        setIsTop(newIsTop)
      }
      setIsRenderForPosition(false)
      await renderTicks(2)
      setIsReady(true)
    }
  }

  useEventListener('wheel', () => {
    if (hideOnWheel) {
      onVisibleChange(false)
    }
  })

  useClickAway(
    (event) => {
      if (disableClickAway?.(event)) {
        return
      }
      onVisibleChange(false)
      onClickAway?.(event)
    },
    [refDropdown, ...toArray(refClickAway ?? refWrap)],
    'mousedown',
  )

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (!open) {
      timer = setTimeout(() => {
        setIsTop(false)
      }, 300)
      setIsReady(false)
    } else {
      onVisibleChange(true)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [open])

  useEffect(() => {
    onVisibleChange(false)
  }, [sizeWindow])

  return (
    <Portal container={getPopupContainerInternal()} disableRender={!isMounted && !isRenderForPosition}>
      <div className={cx(classes.portal, classNamePortal)}>
        <div
          className={cx(classes.dropdown, className, classes[position], {
            [classes.isTop]: isTop,
            [classes.isRenderForPosition]: isRenderForPosition,
          })}
          style={positionWindow}
        >
          <Animation
            initVisible={isRenderForPosition}
            key={String(isRenderForPosition)}
            ref={refDropdown}
            type={parseTypeAnimation(typeAnimation, isTop, position)}
          >
            {(isReady || isRenderForPosition) && children}
          </Animation>
        </div>
      </div>
    </Portal>
  )
}
