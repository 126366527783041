import { NoParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = '/low_code_settings'

interface Endpoints {
  getEditModeSettings: NoParamEndpointConstructor
  updateEditModeSettings: NoParamEndpointConstructor
}

export const editModeEndpoints: Endpoints = {
  getEditModeSettings: () => baseUrl,
  updateEditModeSettings: () => baseUrl,
}
