// Цвета на кнопке Изи
export const colorsAnimationBackground = ['#3bf9f5', '#c8fde4', '#7ef8d3', '#b3fbdf']

// Скорость
export const speedAnimationButtonEasy = 1.5

// Размытие (процент от минимальной стороны)
// т.е размытие в пикселях = min(width, height) * blurAnimationButtonEasy
export const blurAnimationButtonEasy = 0.1

// Количество кругов (50 - значение, которое подходит для большого фона)
export const numberOfCirclesButtonEasy = 20
