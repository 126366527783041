import { CurrentGranularitySlice } from 'modules/granularities/store/useCurrentGranularity/types'
import { StateCreator } from 'zustand'

export const createBlockAGranularitySliceSlice: StateCreator<{
  blockA: CurrentGranularitySlice
}> = (set) => ({
  blockA: {
    current: null,
    set: (current) => set(({ blockA }) => ({ blockA: { ...blockA, current } })),
    clear: () => set(({ blockA }) => ({ blockA: { ...blockA, current: null } })),
  },
})
