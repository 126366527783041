import { devtools } from 'config/devtools'
import { InnovationTabsCodes } from 'modules/innovationTable/interfaces/common'
import { create } from 'zustand'

import { UseCurrentInnovationTab } from './types'

export const useCurrentInnovationTab = create<UseCurrentInnovationTab>()(
  devtools(
    (set) => ({
      currentTab: InnovationTabsCodes.All,
      setCurrentTab: (currentTab) => set({ currentTab }),
      clear: () => set({ currentTab: InnovationTabsCodes.All }),
    }),
    {
      store: 'currentInnovationTab',
    },
  ),
)
