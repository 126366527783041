import { FC, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { matchPath, useLocation } from 'react-router-dom'

import cx from 'clsx'
import { EditModeContent } from 'modules/editMode/components/EditModeContent'
import { EditModeMenu } from 'modules/editMode/components/EditModeMenu'
import { EditModeWidgetDragLayer } from 'modules/editMode/components/EditModeWidgetDragLayer/EditModeWidgetDragLayer'
import { WidgetCategoryTypes } from 'modules/editMode/interfaces'
import { useWidgets } from 'modules/editMode/queries'
import { useEditModeWidgets, useOpenEditMode } from 'modules/editMode/store'
import { Routes } from 'routes/routes'
import { Animation, AnimationTypes } from 'ui/Animation'

import classes from './EditMode.module.scss'

interface EditModeProps {
  className?: string
}

export const EditMode: FC<EditModeProps> = ({ className }) => {
  const { setWidgets } = useEditModeWidgets()
  const { pathname } = useLocation()

  const openEditMode = useOpenEditMode((state) => state.open)
  const closeEditMenu = useOpenEditMode((state) => state.clear)

  const isForecastPage = !!matchPath({ path: Routes.Forecast }, pathname)

  const { widgets } = useWidgets(WidgetCategoryTypes.Forecast)

  useEffect(() => {
    if (widgets.length) {
      setWidgets(widgets)
    }
  }, [widgets.length])

  if (!isForecastPage) {
    if (openEditMode) {
      closeEditMenu()
    }
    return null
  }

  return (
    <Animation className={cx(classes.wrap, className)} type={AnimationTypes.Opacity}>
      {openEditMode ? (
        <DndProvider backend={HTML5Backend}>
          <div className={classes.cont}>
            <EditModeMenu />
            <EditModeContent />
            <EditModeWidgetDragLayer />
          </div>
        </DndProvider>
      ) : null}
    </Animation>
  )
}
