import { Granularities } from 'constants/granularities'
import { Languages } from 'interfaces/translations.interfaces'

export const months: Record<Languages, string[][]> = {
  ru: [
    ['январь', 'января'],
    ['февраль', 'февраля'],
    ['март', 'марта'],
    ['апрель', 'апреля'],
    ['май', 'мая'],
    ['июнь', 'июня'],
    ['июль', 'июля'],
    ['август', 'августа'],
    ['сентябрь', 'сентября'],
    ['октябрь', 'октября'],
    ['ноябрь', 'ноября'],
    ['декабрь', 'декабря'],
  ],
  en: [
    ['january', 'january'],
    ['february', 'february'],
    ['march', 'march'],
    ['april', 'april'],
    ['may', 'may'],
    ['june', 'june'],
    ['july', 'july'],
    ['august', 'august'],
    ['september', 'september'],
    ['october', 'october'],
    ['november', 'november'],
    ['december', 'december'],
  ],
}

export const monthsShort: Record<Languages, string[]> = {
  ru: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
  en: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
}

export const periods: Record<Languages, Record<Granularities, string>> = {
  ru: {
    days: 'В днях',
    weeks: 'В неделях',
    months: 'В месяцах',
    quarters: 'В кварталах',
    years: 'В годах',
  },
  en: {
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
    quarters: 'Quarters',
    years: 'Years',
  },
}
