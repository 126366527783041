import { flatten } from 'lodash'
import { projectsApi } from 'modules/projects/api/methods'
import { ProjectsQueries } from 'modules/projects/queries/types'
import { useProfile } from 'modules/user/queries'
import { useInfiniteQuery } from 'packages/react-query'

export const useSearchProject = (search?: string) => {
  const { profile } = useProfile()

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [ProjectsQueries.SearchProject, search],
    queryFn: ({ pageParam }) => projectsApi.searchProject(search, { page: pageParam }),
    enabled: !!profile,
  })

  return {
    projects: flatten(data?.pages.map((page) => page.results) || []),
    hasNextPage,
    isLoading: isLoading || isFetchingNextPage,
    fetchNextPage,
  }
}
