import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { errorInterceptor } from 'config/api'
import storage from 'config/storage'
import { API_URL, isTest } from 'constants/env'
import { storageRefreshName, storageTokenName } from 'constants/storage'
import { GetOneResponse } from 'interfaces/api.interfaces'
import { RefreshData, TokenResponse } from 'interfaces/auth.interfaces'
import { authEndpoints } from 'modules/auth/api/endpoints'
import { useResetPasswordTokens } from 'modules/resetPassword/store'
import {
  ApiError,
  headersJson,
  paramsSerializerToSnakeCaseArrayBrackets,
  transformRequestToSnakeCase,
  transformResponseToCamelCase,
} from 'packages/api'

const axios = Axios.create({
  baseURL: API_URL,
  paramsSerializer: paramsSerializerToSnakeCaseArrayBrackets,
  transformResponse: transformResponseToCamelCase,
  transformRequest: transformRequestToSnakeCase,
  headers: headersJson,
})

if (isTest) {
  axios.defaults.adapter = require('axios/lib/adapters/http')
}

const setAxiosAuthorizationToken = (token: null | string): void => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

const global: any = typeof window !== 'undefined' ? window : {}
global.deleteToken = () => {
  setAxiosAuthorizationToken(null)
  window.localStorage.removeItem(storageTokenName)
  window.sessionStorage.removeItem(storageTokenName)
}

const refresh = async (form: RefreshData): GetOneResponse<TokenResponse> => {
  const { data } = await axios.request<TokenResponse>({
    method: 'POST',
    url: authEndpoints.refresh(),
    data: form,
  })
  return data
}

interface AxiosExtendedRequestConfig extends AxiosRequestConfig {
  isRetry?: boolean
}

let refreshSent = false
const requestQueue: AxiosExtendedRequestConfig[] = []
const pushToRequestQueue = (request: AxiosExtendedRequestConfig) => {
  requestQueue.push(request)
  return requestQueue.length - 1
}
const getRequestQueue = () => requestQueue

const unauthenticatedRequestInterceptor = async (axiosError: AxiosError) => {
  const originalRequest = axiosError.config as AxiosExtendedRequestConfig
  const resetPasswordTokens = useResetPasswordTokens.getState()
  const refreshToken = storage.getRefresh() || resetPasswordTokens.refresh

  if (
    axiosError.response?.status !== 401 ||
    !axiosError.config ||
    originalRequest.isRetry ||
    originalRequest.url === authEndpoints.refresh() ||
    originalRequest.url === authEndpoints.login()
  ) {
    return Promise.reject(axiosError)
  }

  if (!originalRequest.headers) {
    originalRequest.headers = {}
  }

  if (originalRequest.headers?.['Content-Type'] === 'application/json' && typeof originalRequest.data === 'string') {
    originalRequest.data = JSON.parse(originalRequest.data)
  }

  try {
    originalRequest.isRetry = true
    if (!refreshSent) {
      refreshSent = true
      const tokens = await refresh({ refresh: refreshToken }).catch((error) => {
        throw { ...error, code: 'refreshError' }
      })
      originalRequest.headers.Authorization = `Bearer ${tokens.access}`
      getRequestQueue().forEach((request) => {
        if (!request.headers) {
          request.headers = {}
        }
        request.headers.Authorization = `Bearer ${tokens.access}`
      })

      if (resetPasswordTokens.access) {
        resetPasswordTokens.setAccess(tokens.access)
        resetPasswordTokens.setRefresh(tokens.refresh)
        setAxiosAuthorizationToken(tokens.access)
      } else {
        const tokenLS = window.localStorage.getItem(storageRefreshName)
        storage.setToken(tokens.access, !!tokenLS)
        storage.setRefresh(tokens.refresh, !!tokenLS)
        setAxiosAuthorizationToken(tokens.access)
      }

      refreshSent = false

      const tokenEvent = new Event('newToken')
      window.dispatchEvent(tokenEvent)

      return await axios.request(originalRequest)
    } else {
      const indexRequest = pushToRequestQueue(originalRequest)
      return await new Promise((resolve, reject) => {
        const sendRetryRequest = async () => {
          window.removeEventListener('newToken', sendRetryRequest)
          await axios.request(getRequestQueue()[indexRequest]).then(resolve).catch(reject)
        }
        window.addEventListener('newToken', sendRetryRequest)
      })
    }
  } catch (error) {
    if ((error as ApiError)?.code === 'refreshError') {
      storage.clearToken()
    }
    if (!originalRequest.headers) {
      originalRequest.headers = {}
    }
    delete originalRequest.headers.Authorization
    return Promise.reject({
      ...(error as ApiError),
      isRetryError: true,
    })
  }
}

axios.interceptors.response.use((response) => response, unauthenticatedRequestInterceptor)
axios.interceptors.response.use((response) => response, errorInterceptor)

export { axios, setAxiosAuthorizationToken }
