import { forwardRef, ReactNode, useEffect, useRef } from 'react'

import { useInfiniteScroll } from 'ahooks'
import cx from 'clsx'
import { useCombinedRef } from 'hooks/useCombinedRef'
import { Loader, LoaderColors } from 'ui/Loader'

import classes from './Block.module.scss'

export enum BlockColors {
  Default = 'default',
  Light = 'light',
  Primary = 'primary',
}

interface BlockProps {
  className?: string
  classNameContent?: string
  color?: BlockColors
  children?: ReactNode
  scrollable?: boolean
  loadingOnScroll?: boolean
  onScrollBottom?: () => void
}

export const Block = forwardRef<HTMLDivElement, BlockProps>(
  (
    { className, classNameContent, color = BlockColors.Default, children, scrollable, loadingOnScroll, onScrollBottom },
    ref,
  ) => {
    const refScroll = useRef<HTMLDivElement>(null)
    const refContent = useRef<HTMLDivElement>(null)
    const { cbRef } = useCombinedRef<HTMLDivElement>(ref, refScroll)
    const threshold = 150

    const { loadMore } = useInfiniteScroll((onScrollBottom as any) || (() => {}), {
      target: refScroll,
      threshold,
      manual: !scrollable,
    })

    useEffect(() => {
      if (
        scrollable &&
        refScroll.current &&
        refContent.current &&
        refScroll.current.offsetHeight > refContent.current.clientHeight
      ) {
        loadMore()
      }
    }, [scrollable, refScroll.current, refContent.current?.clientHeight])

    if (scrollable) {
      return (
        <div className={cx(classes.scroll, className)}>
          <div className={cx(classes.wrap, classNameContent, classes[color], 'scroll')} ref={cbRef}>
            <div ref={refContent}>{children}</div>
          </div>
          <Loader className={cx(classes.loader, { [classes.show]: loadingOnScroll })} color={LoaderColors.Dark} />
        </div>
      )
    }

    return <div className={cx(classes.wrap, className, classes[color])}>{children}</div>
  },
)
