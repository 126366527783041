import { FC, useEffect, useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import cx from 'clsx'
import { Widget } from 'modules/editMode/api'
import { EditModeMenuWidget } from 'modules/editMode/components/EditModeMenuWidget/EditModeMenuWidget'
import { useUpdateWidgetsLocal } from 'modules/editMode/handlers'
import { DragAreaName, DraggableItemSizes, WidgetTypes } from 'modules/editMode/interfaces'
import { useProject } from 'modules/projects/queries'

import classes from './EditModeMenuWidgetBlock.module.scss'

interface EditModeMenuWidgetBlockProps {
  className?: string
  item: Widget
}

export const EditModeMenuWidgetBlock: FC<EditModeMenuWidgetBlockProps> = ({ className, item }) => {
  const [canDragging, setCanDragging] = useState(false)
  const dragRef = useRef<HTMLDivElement>(null)

  const { project } = useProject()
  const { applyWidgets } = useUpdateWidgetsLocal()

  const isDeactivatedRecWidget = !project?.cognitiveEnabled && item.code === WidgetTypes.Recommendations

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: canDragging ? DragAreaName.Forecast : '',
      item: { ...item, width: dragRef.current?.clientWidth, size: DraggableItemSizes.Small },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (_, monitor) => {
        if (monitor.didDrop()) {
          applyWidgets?.()
        }
      },
    }),
    [canDragging, item],
  )

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [canDragging])

  return (
    <div className={cx(classes.wrap, className)} ref={drag}>
      <div ref={dragRef}>
        <EditModeMenuWidget
          className={cx({ [classes.isDragging]: isDragging || isDeactivatedRecWidget })}
          isDeactivatedRecWidget={isDeactivatedRecWidget}
          item={item}
          setCanDragging={setCanDragging}
        />
      </div>
    </div>
  )
}
