import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import storage from 'config/storage'
import { IS_DANONE_ENV, IS_JUME_ENV, IS_NPT_ENV } from 'constants/env'
import { projectTypeMap, ProjectTypes } from 'constants/projects'
import { useProject } from 'modules/projects/queries'
import { useLastSession } from 'modules/session/store'
import { useProfile } from 'modules/user/queries'
import { removeByValue } from 'packages/helper'
import {
  cfrOptimizerRoutes,
  forecastRoutes,
  promoOptimizerRoutes,
  replenOptimizerRoutes,
  Routes,
  rtmOptimizerRoutes,
  tetrisOptimizerRoutes,
} from 'routes/routes'

export const useProjectType = () => {
  const [isUnavailable, setIsUnavailable] = useState(false)
  const { pathname } = useLocation()
  const location = useLocation()
  const navigate = useNavigate()
  const { project, isPoc } = useProject()
  const { profile, isFetchedProfile } = useProfile()

  const storageProjectType = storage.getProjectType()

  const typeCode = project?.type.code
  const path = location.pathname

  const forecastLocation = projectTypeMap[ProjectTypes.Forecast]
  const forecastPocLocation = projectTypeMap[ProjectTypes.ForecastPoc]
  const promoLocation = projectTypeMap[ProjectTypes.PromoOptimizer]
  const replenLocation = projectTypeMap[ProjectTypes.ReplenOptimizer]
  const rtmLocation = projectTypeMap[ProjectTypes.RtmOptimizer]
  const tetrisLocation = projectTypeMap[ProjectTypes.TetrisOptimizer]
  const cfrLocation = projectTypeMap[ProjectTypes.CfrOptimizer]

  const locations = [forecastLocation, promoLocation, replenLocation, rtmLocation, tetrisLocation, cfrLocation]
  const lastPage = useLastSession((state) => state.page)
  const isNavigatedToLastPage = useLastSession((state) => state.isNavigated)
  const isShouldRedirectPoc = typeCode === ProjectTypes.Forecast && isPoc && path === Routes.Main

  const handleReturnBack = () => {
    setIsUnavailable(false)
    navigate(-1)
  }

  useEffect(() => {
    if (isShouldRedirectPoc) {
      return navigate(forecastPocLocation)
    }

    if ((!isNavigatedToLastPage && lastPage && IS_JUME_ENV) || !typeCode) {
      return
    }
    if (storageProjectType === typeCode) {
      if (typeCode === ProjectTypes.PromoOptimizer && removeByValue(locations, promoLocation).includes(path)) {
        // return
        return navigate(promoLocation)
      }
      if (typeCode === ProjectTypes.Forecast && removeByValue(locations, forecastLocation).includes(path)) {
        return navigate(forecastLocation)
      }
      if (typeCode === ProjectTypes.ReplenOptimizer && removeByValue(locations, replenLocation).includes(path)) {
        return navigate(replenLocation)
      }
      if (typeCode === ProjectTypes.RtmOptimizer && removeByValue(locations, rtmLocation).includes(path)) {
        return navigate(rtmLocation)
      }
      if (typeCode === ProjectTypes.TetrisOptimizer && removeByValue(locations, tetrisLocation).includes(path)) {
        return navigate(tetrisLocation)
      }
      if (typeCode === ProjectTypes.CfrOptimizer && removeByValue(locations, cfrLocation).includes(path)) {
        return navigate(cfrLocation)
      }
    } else {
      storage.setProjectType(typeCode)
      if (IS_DANONE_ENV || IS_NPT_ENV) {
        return navigate(path === Routes.Main || locations.includes(path) ? projectTypeMap[typeCode] : location)
      } else {
        return navigate(projectTypeMap[typeCode])
      }
    }
  }, [typeCode, isShouldRedirectPoc])

  useEffect(() => {
    if (!profile?.activeProjectId && !typeCode && isFetchedProfile && IS_DANONE_ENV) {
      debugger
      navigate(promoLocation)
    }
  }, [typeCode, isFetchedProfile])

  useEffect(() => {
    if (!Object.values(Routes).includes(pathname as Routes)) {
      return
    }
    if (
      (forecastRoutes.includes(pathname as Routes) && typeCode !== ProjectTypes.Forecast) ||
      (promoOptimizerRoutes.includes(pathname as Routes) && typeCode !== ProjectTypes.PromoOptimizer) ||
      (replenOptimizerRoutes.includes(pathname as Routes) && typeCode !== ProjectTypes.ReplenOptimizer) ||
      (rtmOptimizerRoutes.includes(pathname as Routes) && typeCode !== ProjectTypes.RtmOptimizer) ||
      (tetrisOptimizerRoutes.includes(pathname as Routes) && typeCode !== ProjectTypes.TetrisOptimizer) ||
      (cfrOptimizerRoutes.includes(pathname as Routes) && typeCode !== ProjectTypes.CfrOptimizer)
    ) {
      if (typeCode) {
        setIsUnavailable(true)
      }
    } else {
      setIsUnavailable(false)
    }
  }, [typeCode, pathname])

  return {
    isUnavailable,
    handleReturnBack,
    isRedirecting: isShouldRedirectPoc,
  }
}
