import { Routes } from 'routes/routes'

export enum ProjectTypes {
  Forecast = 'forecast',
  ForecastPoc = 'forecastPoc',
  PromoOptimizer = 'promo_optimizer',
  ReplenOptimizer = 'replen_optimizer',
  RtmOptimizer = 'rtm_optimizer',
  TetrisOptimizer = 'tetris_optimizer',
  CfrOptimizer = 'cfr_optimizer',
}

export const projectTypeMap = {
  [ProjectTypes.Forecast]: Routes.Main,
  [ProjectTypes.ForecastPoc]: Routes.DataSources,
  [ProjectTypes.PromoOptimizer]: Routes.PromoOptimizer,
  [ProjectTypes.ReplenOptimizer]: Routes.ReplenOptimizer,
  [ProjectTypes.RtmOptimizer]: Routes.RtmOptimizer,
  [ProjectTypes.TetrisOptimizer]: Routes.TetrisOptimizer,
  [ProjectTypes.CfrOptimizer]: Routes.CfrOptimizer,
}
