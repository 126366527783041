import { axios } from 'config/axios'
import { GetAllData, GetAllResponse, GetOneResponse, Pagination } from 'interfaces/api.interfaces'
import { deleteEmpty } from 'packages/helper'
import { defaultPagination } from 'utils/pagination'

import { projectsEndpoints } from './endpoints'
import { Project, ProjectInfo } from './interfaces'

const getProject = async (projectId: number): GetOneResponse<Project> => {
  const { data } = await axios.request<Project>({
    method: 'GET',
    url: projectsEndpoints.getProject(projectId),
  })
  return data
}

const getProjectInfo = async (projectId: number): GetOneResponse<ProjectInfo> => {
  const { data } = await axios.request<ProjectInfo>({
    method: 'GET',
    url: projectsEndpoints.getProjectInfo(projectId),
  })
  return data
}

export const searchProject = async (search?: string, pagination?: Pagination): GetAllResponse<Project> => {
  const { data } = await axios.request<GetAllData<Project>>({
    method: 'GET',
    url: projectsEndpoints.getProjects(),
    params: deleteEmpty({
      ...defaultPagination(pagination),
      title: search,
    }),
  })
  return data
}

export const projectsApi = {
  getProject,
  getProjectInfo,
  searchProject,
}
