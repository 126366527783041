import { devtools } from 'config/devtools'
import { MasterDataViewsCodes } from 'modules/masterData/interfaces/common'
import { create } from 'zustand'

import { UseCurrentMasterDataView } from './types'

export const useCurrentMasterDataView = create<UseCurrentMasterDataView>()(
  devtools(
    (set) => ({
      currentView: MasterDataViewsCodes.List,
      setCurrentView: (currentView) => set({ currentView }),
    }),
    {
      store: 'currentMasterDataView',
    },
  ),
)
