import { ChangeEvent, FormEvent, forwardRef, MutableRefObject, useEffect, useRef, useState } from 'react'
import InputMask from 'react-input-mask'
import TextareaAutosize from 'react-textarea-autosize'

import { ReactComponent as CloseIcon } from 'assets/images/close-16.svg'
import cx from 'clsx'
import { useCombinedRef } from 'hooks/useCombinedRef'
import { InputProps, TextFieldColors, TextFieldSizes, TextFieldTypes } from 'interfaces/components.interfaces'
import { toNumberString } from 'packages/helper'
import { Loader, LoaderTypes } from 'ui/Loader'
import { antiAutoComplete } from 'utils/antiAutoComplete'

import classes from './TextField.module.scss'

export const TextField = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
  (
    {
      className,
      classNameContainer,
      classNameAfter,
      classNameFocus,
      type = TextFieldTypes.Default,
      size = TextFieldSizes.Default,
      color = TextFieldColors.Default,
      autoComplete = 'off',
      name,
      value,
      placeholder,
      mask = '',
      htmlType = 'text',
      disabled,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      loading,
      maxLength,
      multiline,
      autoResize,
      autoFocus,
      maxRows,
      noWrap,
      afterContent,
      onClickAfter,
      afterIsClickable,
      isCell,
      isError,
      noEditable,
      isChanged,
      isCleanable,
    },
    ref,
  ) => {
    const [valueInternal, setValueInternal] = useState(value)
    const [isFocused, setIsFocused] = useState(false)

    const refInternal = useRef<HTMLInputElement | HTMLTextAreaElement>(null)
    const { cbRef } = useCombinedRef<HTMLInputElement | HTMLTextAreaElement>(ref, refInternal)

    const onChangeInternal = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      let newValue = e.target.value
      if (
        htmlType === 'number' &&
        ((!/^[0-9.,-]+$/.test(newValue.replace(/,/, '.')) && newValue !== '') ||
          newValue.replace(/,/, '.').split('.').length - 1 > 1 ||
          newValue.split('-').length - 1 > 1 ||
          (newValue.length > 1 && newValue.indexOf('-') > 0 && newValue !== '0-'))
      ) {
        return
      }
      if (htmlType === 'number') {
        newValue = toNumberString(newValue) ?? ''
      }
      if (onChange) {
        onChange(newValue)
      }
      setValueInternal(newValue)
    }

    const onFocusInternal = (e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) => {
      setIsFocused(true)
      if (onFocus) {
        onFocus(e)
      }
    }

    const onBlurInternal = (e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) => {
      setIsFocused(false)
      if (onBlur) {
        onBlur(e)
      }
    }

    const onWheel = (e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) =>
      (e.target as HTMLInputElement).blur()

    const onClear = () => {
      onChange?.('')
      setValueInternal('')
    }

    useEffect(() => {
      if (refInternal.current && autoFocus) {
        refInternal.current.focus()
      }
    }, [refInternal.current, autoFocus])

    useEffect(() => {
      setValueInternal(value)
    }, [value])

    return (
      <div
        className={cx(classes.wrap, classNameContainer, {
          disabledInput: disabled,
          [classes.noWrap]: noWrap,
          [classes.isCell]: isCell,
          [classes.isFocused]: isFocused,
          [classes.disabled]: disabled,
          [classes.noEditable]: noEditable,
          [classes.isError]: isError,
          [classes.noMultiline]: !multiline,
          [classes.isChanged]: isChanged,
        })}
        onClick={() => ((ref ?? refInternal) as MutableRefObject<HTMLInputElement | null>).current?.focus()}
      >
        <div className={classes.cont}>
          {noEditable && (
            <span className={cx(classes.input, className)} data-type="text">
              {valueInternal}
            </span>
          )}
          {!noEditable && !multiline && (
            <InputMask
              autoComplete={autoComplete}
              autoFocus={autoFocus}
              className={cx(
                classes.input,
                className,
                classes[color],
                classes[type],
                classes[size],
                classNameFocus && { [classNameFocus]: isFocused },
                { [classes.withCancel]: isCleanable && value, [classes.withAfter]: afterContent },
              )}
              data-focus={isFocused}
              data-type={color}
              disabled={disabled}
              inputRef={cbRef}
              mask={mask}
              maxLength={maxLength}
              name={name}
              onBlur={onBlurInternal}
              onChange={onChangeInternal}
              onFocus={onFocusInternal}
              onKeyDown={onKeyDown}
              onWheel={onWheel}
              placeholder={typeof placeholder === 'string' && placeholder ? antiAutoComplete(placeholder) : undefined}
              type={htmlType}
              value={valueInternal || ''}
            />
          )}
          {!noEditable && multiline && !autoResize && (
            <textarea
              autoFocus={autoFocus}
              className={cx(
                classes.input,
                className,
                classes[color],
                classes[type],
                classes[size],
                classNameFocus && { [classNameFocus]: isFocused },
                { [classes.withCancel]: isCleanable && value, [classes.withAfter]: afterContent },
              )}
              data-focus={isFocused}
              data-type={color}
              disabled={disabled}
              maxLength={maxLength}
              onBlur={onBlurInternal}
              onChange={onChangeInternal}
              onFocus={onFocusInternal}
              onKeyDown={onKeyDown}
              onWheel={onWheel}
              placeholder={placeholder}
              ref={cbRef}
              value={valueInternal || ''}
            />
          )}
          {!noEditable && multiline && autoResize && (
            <TextareaAutosize
              autoFocus={autoFocus}
              className={cx(
                classes.input,
                className,
                classes[color],
                classes[type],
                classes[size],
                classNameFocus && { [classNameFocus]: isFocused },
                { [classes.withCancel]: isCleanable && value, [classes.withAfter]: afterContent },
              )}
              data-focus={isFocused}
              data-type={color}
              disabled={disabled}
              maxLength={maxLength}
              maxRows={maxRows}
              onBlur={onBlurInternal}
              onChange={onChangeInternal}
              onFocus={onFocusInternal}
              onKeyDown={onKeyDown}
              onWheel={onWheel}
              placeholder={placeholder}
              ref={cbRef}
              value={valueInternal || ''}
            />
          )}

          {loading && (
            <Loader className={cx(classes.loader, { [classes.after]: afterContent })} type={LoaderTypes.Spinner} />
          )}
          {afterContent && (
            <div
              className={cx(classes.afterContent, classNameAfter, { [classes.afterIsClickable]: afterIsClickable })}
              onClick={onClickAfter}
            >
              {afterContent}
            </div>
          )}

          {isCleanable && value && (
            <div className={classes.clearButton} onClick={onClear}>
              <CloseIcon />
            </div>
          )}
        </div>
      </div>
    )
  },
)
