import { FC } from 'react'

import { NavLink } from 'components/template/NavLink'
import classes from 'components/template/NavMenu/NavMenu.module.scss'
import { useTranslate } from 'config/i18n'
import { Routes } from 'routes/routes'

export const NavMenuNptDemand: FC = () => {
  const translate = useTranslate()

  return (
    <>
      <NavLink className={classes.menuItem} path={Routes.Main}>
        {translate('menu.main')}
      </NavLink>

      <NavLink className={classes.menuItem} path={Routes.DataSources}>
        {translate('menu.data')}
      </NavLink>

      <NavLink className={classes.menuItem} path={Routes.Selections}>
        {translate('menu.selections')}
      </NavLink>
    </>
  )
}
