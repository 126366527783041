import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'
import { setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'
import { useUnsavedChanges } from 'hooks/useUnsavedChanges'
import { useLastSession } from 'modules/session/store'
import { Routes } from 'routes/routes'

export const useLogoutClear = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const clearUnsavedChanges = useUnsavedChanges((state) => state.clear)
  const setIsNavigatedToLastPage = useLastSession((state) => state.setIsNavigated)

  const logoutClear = useCallback(() => {
    storage.clearToken()
    storage.clearProjectType()
    setAxiosAuthorizationToken(null)
    navigate(Routes.Login)
    queryClient.removeQueries()
    clearUnsavedChanges()
    setIsNavigatedToLastPage(false)
  }, [queryClient])

  return { logoutClear }
}
