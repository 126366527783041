import { FC } from 'react'
import { useDragLayer } from 'react-dnd'

import cx from 'clsx'
import { EditModeMenuWidget } from 'modules/editMode/components/EditModeMenuWidget'
import { EditModeWidget } from 'modules/editMode/components/EditModeWidget/EditModeWidget'
import { getDraggableItemStyle } from 'modules/editMode/helpers/getDraggableItemStyle'
import { DraggableItemSizes } from 'modules/editMode/interfaces'

import classes from './EditModeWidgetDragLayer.module.scss'

interface EditModeWidgetDragLayerProps {
  className?: string
}

export const EditModeWidgetDragLayer: FC<EditModeWidgetDragLayerProps> = ({ className }) => {
  const { isDragging, currentFileOffset, item } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentFileOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging) {
    return null
  }

  return (
    <div className={cx(classes.wrap, className)}>
      <div style={getDraggableItemStyle(currentFileOffset, item.width)}>
        {item?.size === DraggableItemSizes.Big ? (
          <EditModeWidget isDragging={isDragging} item={item} />
        ) : (
          <EditModeMenuWidget item={item} />
        )}
      </div>
    </div>
  )
}
