import { i18n } from 'config/i18n'
import { OptionTreeItem } from 'interfaces/components.interfaces'
import { isEqual } from 'lodash'
import { toArray } from 'packages/helper'
import { flattenOptionTree } from 'utils/flattenOptionTree'

interface LabelParsingOptions<ValueType extends string | number | (number | string)[]> {
  isTree?: boolean
  multiple?: boolean
  showMultipleLabel?: boolean
  options?: OptionTreeItem[]
  valueInternal?: ValueType | null
  valueStringWhenNotLoaded?: string
  valueTreeWhenNotLoaded?: Record<number, string>
  label?: string
  labelWithItems?: boolean
  selectedElementsNames?: Record<number, string>
  selectedOptions?: OptionTreeItem[]
}
export const parseLabel = <ValueType extends string | number | (number | string)[]>({
  isTree,
  multiple,
  showMultipleLabel,
  options,
  valueInternal,
  valueStringWhenNotLoaded,
  valueTreeWhenNotLoaded,
  label,
  labelWithItems,
  selectedElementsNames,
  selectedOptions,
}: LabelParsingOptions<ValueType>) => {
  let labelInternal = label

  if (!isTree && multiple && showMultipleLabel && options?.length) {
    labelInternal = flattenOptionTree(options)
      ?.filter((item) => toArray(valueInternal).includes(item.value))
      .map((item) => item.label)
      .join(', ')
  }

  if (
    isTree &&
    !multiple &&
    showMultipleLabel &&
    valueStringWhenNotLoaded &&
    !flattenOptionTree(options)?.find((item) => item.value === valueInternal)
  ) {
    labelInternal = valueStringWhenNotLoaded
  }

  if (isTree && multiple && showMultipleLabel && valueInternal) {
    labelInternal = (valueInternal as unknown as number[])
      .map(
        (id) =>
          selectedElementsNames?.[id] ??
          flattenOptionTree(options)?.find((item) => item.value === id)?.label ??
          selectedOptions?.find((item) => item.value === id)?.label ??
          valueTreeWhenNotLoaded?.[id],
      )
      .join(', ')
  }

  if (labelWithItems && valueInternal && valueInternal !== 'all' && labelInternal) {
    labelInternal = `${label}: ${multiple ? labelInternal : valueInternal}`
  }

  if (labelWithItems && (!valueInternal || valueInternal === 'all' || isEqual(valueInternal, []))) {
    labelInternal = `${label}: ${i18n.get('select.any')}`
  }

  if (!labelInternal) {
    labelInternal = label
  }

  return labelInternal
}
