import { FC, memo, useEffect, useRef } from 'react'

import cx from 'clsx'
import {
  blurAnimationButtonEasy,
  colorsAnimationBackground,
  numberOfCirclesButtonEasy,
  speedAnimationButtonEasy,
} from 'constants/animationBackground'
import { random } from 'lodash'
import type { Application as PixiApplication } from 'pixi.js'
import { createAnimationBackground } from 'utils/animationBackground'

import classes from './AnimationBackground.module.scss'

interface AnimationBackgroundProps {
  className?: string
  speed?: number
  colors?: string[]
  blur?: number
  numberOfCircles?: number
  isCanvas?: boolean
}

export const AnimationBackground: FC<AnimationBackgroundProps> = memo(
  ({
    className,
    speed = speedAnimationButtonEasy,
    colors = colorsAnimationBackground,
    blur = blurAnimationButtonEasy,
    numberOfCircles = numberOfCirclesButtonEasy,
    isCanvas = false,
  }) => {
    const ref = useRef(null)

    useEffect(() => {
      if (!isCanvas) {
        return
      }
      let app: PixiApplication
      if (ref.current) {
        app = createAnimationBackground(ref.current, speed, colors, blur, numberOfCircles)
      }

      return () => {
        app?.destroy()
      }
    }, [ref.current, speed, colors, blur, numberOfCircles, isCanvas])

    if (isCanvas) {
      return <canvas className={cx(classes.wrap, className)} ref={ref} />
    }

    return (
      <div className={cx(classes.wrap, classes.wrapDiv, className)}>
        {Array.from({ length: numberOfCircles }).map((_, index) => (
          <div
            className={classes.circle}
            key={index}
            style={{
              backgroundColor: colorsAnimationBackground[random(0, colorsAnimationBackground.length - 1)],
              left: `${random(-80, 80, true)}%`,
              top: `${random(-80, 80, true)}%`,
            }}
          />
        ))}
      </div>
    )
  },
)
