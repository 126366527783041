import { useCallback, useMemo } from 'react'

import {
  QueryClient,
  QueryFilters,
  QueryKey,
  SetDataOptions,
  Updater,
  useQueryClient as useQueryClientNative,
} from '@tanstack/react-query'
import produce from 'immer'
import { WritableDraft } from 'packages/react-query/src/types'

export const useQueryClient = () => {
  const queryClient = useQueryClientNative()
  const queryClientCopy = useMemo(
    () =>
      new QueryClient({
        queryCache: queryClient.getQueryCache(),
        mutationCache: queryClient.getMutationCache(),
        defaultOptions: queryClient.getDefaultOptions(),
      }),
    [],
  )
  const setQueryDataNative = useCallback(queryClientCopy.setQueryData.bind(queryClientCopy), [])
  const setQueriesDataNative = useCallback(queryClientCopy.setQueriesData.bind(queryClientCopy), [])

  const setQueryData = <TQueryFnData>(
    queryKey: QueryKey,
    updater: (state: WritableDraft<TQueryFnData>) => void | TQueryFnData,
    options?: SetDataOptions,
  ): TQueryFnData | undefined =>
    setQueryDataNative(
      queryKey,
      ((state) => {
        if (state === undefined) {
          return
        }
        return produce(state ?? {}, (draft) => updater(draft as WritableDraft<TQueryFnData>) as any)
      }) as Updater<TQueryFnData | undefined, TQueryFnData | undefined>,
      options,
    )

  const setQueriesData = <TQueryFnData>(
    filters: QueryFilters,
    updater: (state: WritableDraft<TQueryFnData>) => void | TQueryFnData,
    options?: SetDataOptions,
  ): [QueryKey, TQueryFnData | undefined][] =>
    setQueriesDataNative(
      filters,
      ((state) => {
        if (state === undefined) {
          return
        }
        return produce(state ?? {}, (draft) => updater(draft as WritableDraft<TQueryFnData>) as any)
      }) as Updater<TQueryFnData | undefined, TQueryFnData | undefined>,
      options,
    )

  queryClientCopy.setQueryData = setQueryData as typeof queryClientCopy.setQueryData
  queryClientCopy.setQueriesData = setQueriesData as typeof queryClientCopy.setQueriesData

  return queryClientCopy as Omit<QueryClient, 'setQueryData' | 'setQueriesData'> & {
    setQueryData: typeof setQueryData
    setQueriesData: typeof setQueriesData
  }
}
