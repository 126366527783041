import { useQuery } from '@tanstack/react-query'
import { useTranslate } from 'config/i18n'
import { projectsApi } from 'modules/projects/api'
import { ProjectsQueries } from 'modules/projects/queries/types'
import { useProfile } from 'modules/user/queries'

export const useProjectInfo = () => {
  const { profile } = useProfile()
  const translate = useTranslate()

  const enabled = !!profile?.activeProjectId

  const { data, isPending, refetch } = useQuery({
    queryKey: [ProjectsQueries.ProjectInfo],
    queryFn: () => {
      if (profile?.activeProjectId) {
        return projectsApi.getProjectInfo(profile.activeProjectId)
      }
    },
    enabled,
    meta: {
      error: translate('errorsNoData.projectInfo'),
    },
  })

  return {
    projectInfo: data,
    isLoadingProjectInfo: isPending && enabled,
    refetchProjectInfo: refetch,
  }
}
