import { GetAllData } from 'interfaces/api.interfaces'
import { DelistingTableRow } from 'modules/delistingTable/api'
import { PaginateData } from 'packages/react-query'

export enum DelistingTabsCodes {
  All = 'all',
  Inactive = 'inactive',
}

export type DelistingAllData = Record<DelistingTabsCodes, PaginateData<GetAllData<DelistingTableRow>> | undefined>
