import { ForecastQueries } from 'modules/forecast/queries/types'
import { BlockAQueries } from 'modules/forecastBlockA/queries/types'
import { BlockBQueries } from 'modules/forecastBlockB/queries/types'
import { KpiQueries } from 'modules/kpi/queries/types'
import { useProject } from 'modules/projects/queries'
import { useQueryClient } from 'packages/react-query'

export const useClearCashForecast = () => {
  const queryClient = useQueryClient()
  const { project } = useProject()

  const clearCashForecast = () => {
    queryClient.removeQueries({ queryKey: [ForecastQueries.Forecast, project?.id] })
    queryClient.removeQueries({ queryKey: [BlockAQueries.ForecastChart] })
    queryClient.removeQueries({ queryKey: [BlockBQueries.DemandDriverChart] })
    queryClient.removeQueries({ queryKey: [KpiQueries.ZoneOne] })
    queryClient.removeQueries({ queryKey: [KpiQueries.ZoneTwo] })
    queryClient.removeQueries({ queryKey: [KpiQueries.ZoneThree] })
    queryClient.removeQueries({ queryKey: [KpiQueries.ZoneFour] })
  }

  return { clearCashForecast }
}
