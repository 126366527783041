import { GetAllData } from 'interfaces/api.interfaces'
import { PromoTableRow } from 'modules/promoTable/api'
import { PaginateData } from 'packages/react-query'

export enum PromoTabsCodes {
  All = 'all',
  Regular = 'regular',
  Archive = 'archive',
}

export type PromoAllData = Record<PromoTabsCodes, PaginateData<GetAllData<PromoTableRow>> | undefined>
