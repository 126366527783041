import { CSSProperties, FC, MouseEventHandler, ReactNode, useRef, useState } from 'react'

import cx from 'clsx'
import { Dropdown } from 'components/template/Dropdown'
import { AnimationTypes } from 'ui/Animation'

import classes from './Tooltip.module.scss'

interface TooltipProps {
  className?: string
  classNameContainer?: string
  classNameInfo?: string
  position?: 'top' | 'topLeft' | 'topRight' | 'bottom' | 'bottomLeft' | 'bottomRight'
  children?: ReactNode
  tooltip?: ReactNode
  disabled?: boolean
  isHovered?: boolean
  onClick?: () => void
  style?: CSSProperties
  styleContainer?: CSSProperties
  cursorPointer?: boolean
}

export const Tooltip: FC<TooltipProps> = ({
  className,
  classNameContainer,
  classNameInfo,
  children,
  position = 'top',
  tooltip,
  isHovered,
  disabled,
  onClick,
  style,
  styleContainer,
  cursorPointer = true,
}) => {
  const [open, setOpen] = useState(false)

  const refContent = useRef<HTMLDivElement>(null)

  const onClickInternal: MouseEventHandler = () => {
    if (!disabled) {
      if (!isHovered) {
        setOpen(true)
      }
    }
    onClick?.()
  }

  const onMouseOver = () => {
    if (isHovered && !disabled) {
      setOpen(true)
    }
  }

  const onMouseOut = () => {
    if (isHovered && !disabled) {
      setOpen(false)
    }
  }

  return (
    <>
      <div
        className={cx(classes.wrap, className, classes[position])}
        data-stop-propagation="true"
        onClick={onClickInternal}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        ref={refContent}
        style={styleContainer}
      >
        <div className={cx(classes.cont, classNameContainer, { [classes.cursorPointer]: cursorPointer })} style={style}>
          {children}
        </div>
      </div>
      <Dropdown
        offsetY={12}
        open={open}
        position={position}
        refWrap={refContent}
        setOpen={setOpen}
        typeAnimation={AnimationTypes.ScaleIn}
      >
        {open && tooltip && <div className={cx(classes.tooltip, classNameInfo)}>{tooltip}</div>}
      </Dropdown>
    </>
  )
}
