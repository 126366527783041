import { systemLang } from 'config/systemLang'
import { ProjectTypes } from 'constants/projects'
import {
  getStorageCfrScenarioPFRName,
  getStoragePromoScenarioPFRName,
  getStorageRtmScenarioPFRName,
  getStorageTetrisScenarioPFRName,
  storageAuthName,
  storageLang,
  storageProjectType,
  storageRefreshName,
  storageTokenName,
} from 'constants/storage'
import { Languages } from 'interfaces/translations.interfaces'

// Для работы хука useLocalStorage
const dispatchEventStorage = () => {
  const eventLocalStorage = new Event('local-storage')
  window.dispatchEvent(eventLocalStorage)
  const eventSessionStorage = new Event('session-storage')
  window.dispatchEvent(eventSessionStorage)
}

const storage = {
  setItem: <T>(name: string, data: T): void => {
    const stringifiedData = JSON.stringify(data)
    window.localStorage.setItem(name, stringifiedData)
  },
  getItem: <T>(name: string): T | null => {
    const data = window.localStorage.getItem(name)
    if (!data) {
      return null
    }
    return JSON.parse(data) as T
  },
  removeItem: (key: string) => {
    window.localStorage.removeItem(key)
    window.sessionStorage.removeItem(key)
  },
  getToken: (): string => {
    const tokenLS = window.localStorage.getItem(storageTokenName) as string
    const tokenSession = window.sessionStorage.getItem(storageTokenName) as string
    return tokenLS ? tokenLS : tokenSession
  },
  setToken: (token: string, remember?: boolean): void => {
    const store = remember ? window.localStorage : window.sessionStorage
    const clearedStore = remember ? window.sessionStorage : window.localStorage
    store.setItem(storageTokenName, token)
    store.setItem(storageAuthName, JSON.stringify({ value: !!token }))
    clearedStore.removeItem(storageTokenName)
    clearedStore.removeItem(storageAuthName)
    dispatchEventStorage()
  },
  getRefresh: (): string => {
    const tokenLS = window.localStorage.getItem(storageRefreshName) as string
    const tokenSession = window.sessionStorage.getItem(storageRefreshName) as string
    return tokenLS ? tokenLS : tokenSession
  },
  setRefresh: (refresh: string, remember?: boolean): void => {
    const store = remember ? window.localStorage : window.sessionStorage
    const clearedStore = remember ? window.sessionStorage : window.localStorage
    store.setItem(storageRefreshName, refresh)
    clearedStore.removeItem(storageRefreshName)
  },
  clearToken: (): void => {
    window.localStorage.removeItem(storageLang)
    window.localStorage.removeItem(storageTokenName)
    window.localStorage.removeItem(storageAuthName)
    window.localStorage.removeItem(storageRefreshName)
    window.sessionStorage.removeItem(storageTokenName)
    window.sessionStorage.removeItem(storageAuthName)
    window.sessionStorage.removeItem(storageRefreshName)
    dispatchEventStorage()
  },
  getLang: (): Languages => {
    const lang = window.localStorage.getItem(storageLang)
    if (!lang) {
      return systemLang
    }
    return (JSON.parse(lang)?.state?.lang as Languages) || systemLang
  },
  setProjectType: (project: ProjectTypes) => {
    window.sessionStorage.setItem(storageProjectType, JSON.stringify({ project }))
  },
  getProjectType: () => {
    const projectTypeLS = window.sessionStorage.getItem(storageProjectType)
    if (!projectTypeLS) {
      return null
    }
    return JSON.parse(projectTypeLS).project as ProjectTypes
  },
  clearProjectType: () => {
    window.sessionStorage.removeItem(storageProjectType)
  },
  setPromoScenarioPFR: (scenarioId: number, params: any) => {
    window.localStorage.setItem(getStoragePromoScenarioPFRName(scenarioId), JSON.stringify(params))
  },
  getPromoScenarioPFR: (scenarioId: number) => {
    const paramsLS = window.localStorage.getItem(getStoragePromoScenarioPFRName(scenarioId))
    if (!paramsLS) {
      return null
    }
    return JSON.parse(paramsLS)
  },
  clearPromoScenarioPFR: (scenarioId: number) => {
    window.localStorage.removeItem(getStoragePromoScenarioPFRName(scenarioId))
  },
  setRtmScenarioPFR: (scenarioId: number, params: any) => {
    window.localStorage.setItem(getStorageRtmScenarioPFRName(scenarioId), JSON.stringify(params))
  },
  getRtmScenarioPFR: (scenarioId: number) => {
    const paramsLS = window.localStorage.getItem(getStorageRtmScenarioPFRName(scenarioId))
    if (!paramsLS) {
      return null
    }
    return JSON.parse(paramsLS)
  },
  clearRtmScenarioPFR: (scenarioId: number) => {
    window.localStorage.removeItem(getStorageRtmScenarioPFRName(scenarioId))
  },
  setTetrisScenarioPFR: (scenarioId: number, params: any) => {
    window.localStorage.setItem(getStorageTetrisScenarioPFRName(scenarioId), JSON.stringify(params))
  },
  getTetrisScenarioPFR: (scenarioId: number) => {
    const paramsLS = window.localStorage.getItem(getStorageTetrisScenarioPFRName(scenarioId))
    if (!paramsLS) {
      return null
    }
    return JSON.parse(paramsLS)
  },
  clearTetrisScenarioPFR: (scenarioId: number) => {
    window.localStorage.removeItem(getStorageTetrisScenarioPFRName(scenarioId))
  },
  setCfrScenarioPFR: (scenarioId: number, params: any) => {
    window.localStorage.setItem(getStorageCfrScenarioPFRName(scenarioId), JSON.stringify(params))
  },
  getCfrScenarioPFR: (scenarioId: number) => {
    const paramsLS = window.localStorage.getItem(getStorageCfrScenarioPFRName(scenarioId))
    if (!paramsLS) {
      return null
    }
    return JSON.parse(paramsLS)
  },
  clearCfrScenarioPFR: (scenarioId: number) => {
    window.localStorage.removeItem(getStorageCfrScenarioPFRName(scenarioId))
  },
}

export default storage
