import { FC, ReactNode } from 'react'

import cx from 'clsx'
import { useTranslate } from 'config/i18n'

import classes from './Loader.module.scss'

export enum LoaderTypes {
  Default = 'default',
  Opacity = 'opacity',
  Spinner = 'spinner',
  SpinnerMini = 'spinnerMini',
  SpinnerLarge = 'spinnerLarge',
  SpinnerCenter = 'spinnerCenter',
  Text = 'text',
  Mini = 'mini',
}

export enum LoaderColors {
  Default = 'default',
  White = 'white',
  Dark = 'dark',
  Danger = 'danger',
}

interface LoaderProps {
  className?: string
  classNameCont?: string
  classNameWheel?: string
  type?: LoaderTypes
  color?: LoaderColors
  text?: ReactNode | true
  isBlock?: boolean
}

export const Loader: FC<LoaderProps> = ({
  className,
  classNameCont,
  classNameWheel,
  type = LoaderTypes.Default,
  color = LoaderColors.Default,
  text,
  isBlock,
}) => {
  const translate = useTranslate()

  if (type === LoaderTypes.Text) {
    return <div className={cx(classes.text, className)}>{text || translate('load')}</div>
  }

  return (
    <div
      className={cx(classes.loaderCont, className, {
        [classes.isBlock]: isBlock,
        [classes.full]: type === LoaderTypes.SpinnerCenter,
      })}
    >
      {type === LoaderTypes.Spinner ||
      type === LoaderTypes.SpinnerMini ||
      type === LoaderTypes.SpinnerLarge ||
      type === LoaderTypes.SpinnerCenter ? (
        <div className={cx(classes[type], classes[color])}>
          <div className={cx(classes.cont, classNameCont)}>
            <div className={cx(classes.wheel, classNameWheel)} />
          </div>
        </div>
      ) : (
        <div className={cx(classes.loader, classes[type], classes[color])} key={type}>
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      {text && <span className={classes.loaderText}>{text === true ? translate('loadingData') : text}</span>}
    </div>
  )
}
