import { FC, ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { ModalContext } from 'context/ModalContext'
import { useIsMounted } from 'usehooks-ts'
import { isIos } from 'utils/isIos'

const classOpenBody = 'is-open-popup'
const classOpenHtml = 'is-open-popup-html'

interface ModalLayoutProps {
  children?: ReactNode
}

export const ModalLayout: FC<ModalLayoutProps> = ({ children }) => {
  const [openedStatus, setOpenedStatus] = useState<Record<number, boolean>>({})
  const [duration, setDuration] = useState(200)
  const [interval, setInterval] = useState<number>()

  const isMounted = useIsMounted()
  const location = useLocation()

  const isIosDevice = isIos()

  const setOpened = (index: number | undefined, value: boolean): number => {
    let updatedIndex = index ?? 0
    if (typeof index === 'undefined') {
      setOpenedStatus((prev) => {
        const keys = Object.keys(prev).map((key) => Number(key))
        updatedIndex = keys.length ? Math.max(...keys) + 1 : 0
        return { ...prev, [updatedIndex]: value }
      })
    } else {
      setOpenedStatus((prev) => {
        prev[index] = value
        return { ...prev }
      })
    }
    return updatedIndex
  }

  const unmountPopup = (index: number) => {
    setOpenedStatus((prev) => {
      delete prev[index]
      return { ...prev }
    })
  }

  useEffect(() => {
    if (interval) {
      clearTimeout(interval)
    }
    const isOpened = !!Object.values(openedStatus).filter((value) => value).length
    const html = document.querySelector('html')
    const body = document.body
    if (body && html) {
      if (isOpened) {
        if (!body.classList.contains(classOpenBody) && body.scrollHeight > body.offsetHeight) {
          html.classList.add(classOpenHtml)
          body.classList.add(classOpenBody)
        }
        const scrollTop = window.scrollY
        setTimeout(() => {
          const targetElements = body.querySelectorAll('[data-scroll]')
          if (targetElements.length) {
            Array.from(targetElements).forEach((targetElement) => disableBodyScroll(targetElement))
          }
          if (isIosDevice) {
            window.requestAnimationFrame(() => {
              body.style.top = `${-scrollTop}px`
              setTimeout(() => {
                body.style.top = `${-scrollTop}px`
              })
            })
          }
        })
      } else {
        const inter = setTimeout(() => {
          if (isMounted() && body.classList.contains(classOpenBody)) {
            body.classList.remove(classOpenBody)
            html.classList.remove(classOpenHtml)
            clearAllBodyScrollLocks()
          }
        }, duration)
        setInterval(inter as unknown as number)
      }
    }
  }, [openedStatus, isMounted])

  useEffect(() => {
    setOpenedStatus({})
  }, [location?.pathname])

  return (
    <ModalContext.Provider
      value={{
        openedStatus,
        setOpened,
        unmountModal: unmountPopup,
        setDuration,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
