import { useQuery } from '@tanstack/react-query'
import { IS_WORKFLOW_ENABLED } from 'constants/env'
import { useProfile } from 'modules/user/queries'
import { workflowApi } from 'modules/workflow/api'
import { useLastWorkflowDate as getLastWorkflowDate } from 'modules/workflow/store'
import { noRetryOnError } from 'packages/react-query'

import { WorkflowQueries } from './types'

export const useWFIndicator = () => {
  const { profile } = useProfile()
  const enabled = !!profile && IS_WORKFLOW_ENABLED

  const { data, isLoading } = useQuery({
    queryKey: [WorkflowQueries.WorkflowIndicator],
    queryFn: () => workflowApi.getWorkflowIndicator(getLastWorkflowDate.getState().date),
    enabled,
    meta: {
      error: { showToast: false },
    },
    ...noRetryOnError,
    refetchInterval: 5000,
  })

  return {
    data: !!data?.result,
    isLoading,
  }
}
