import { FC, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { ReactComponent as EditModeIcon } from 'assets/images/edit-mode.svg'
import { ReactComponent as LogoutIcon } from 'assets/images/logout.svg'
import { ReactComponent as ProfileIcon } from 'assets/images/user.svg'
import cx from 'clsx'
import { ConfirmModal } from 'components/modals/ConfirmModal'
import { useTranslate } from 'config/i18n'
import { IS_JUME_ENV, IS_LOWCODE_ENABLED } from 'constants/env'
import { useLogout } from 'hooks/useLogout'
import { useModal } from 'hooks/useModal'
import { CognitiveChat } from 'modules/cognitiveChat/components/CognitiveChat'
import { WidgetCategoryTypes } from 'modules/editMode/interfaces'
import { useWidgets } from 'modules/editMode/queries'
import { useOpenEditMode } from 'modules/editMode/store'
import { useProject } from 'modules/projects/queries'
import { Routes } from 'routes/routes'

import classes from './ProfileLinks.module.scss'

interface ProfileLinkProps {
  className?: string
  extended?: boolean
}

export const ProfileLinks: FC<ProfileLinkProps> = ({ className, extended }) => {
  const [loading, setLoading] = useState(false)
  const translate = useTranslate()

  const [openConfirm, onOpenConfirm, onCloseConfirm] = useModal()
  const { logout } = useLogout()
  const openEditMode = useOpenEditMode((state) => state.open)
  const toggleEditMode = useOpenEditMode((state) => state.toggle)
  const { pathname } = useLocation()
  const { widgets } = useWidgets(WidgetCategoryTypes.Forecast)
  const { project } = useProject()

  const isForecastPage = !!matchPath({ path: Routes.Forecast }, pathname)

  const onClickLogout = () => {
    setLoading(true)
    logout()
  }

  return (
    <div className={cx(classes.wrap, className, { [classes.extended]: extended, [classes.topIndex]: openEditMode })}>
      {extended ? (
        <span className={classes.exit} onClick={onOpenConfirm}>
          <LogoutIcon className={classes.icon} />
          {translate('logout')}
        </span>
      ) : (
        <>
          {isForecastPage && !!widgets?.length && IS_LOWCODE_ENABLED && (
            <EditModeIcon className={cx(classes.icon, { [classes.active]: openEditMode })} onClick={toggleEditMode} />
          )}
          {IS_JUME_ENV && project?.cognitiveChatEnabled && <CognitiveChat />}
          <ProfileIcon className={cx(classes.icon, classes.disable)} />
          <LogoutIcon className={classes.icon} onClick={onOpenConfirm} />
        </>
      )}

      <ConfirmModal
        closeAfterSuccess
        danger
        isOpened={openConfirm}
        loading={loading}
        okText={translate('logout')}
        onClose={onCloseConfirm}
        onOk={onClickLogout}
        title={translate('sureLogout')}
      />
    </div>
  )
}
