import { forwardRef, ReactElement, ReactNode, Ref, useEffect, useRef, useState } from 'react'

import { useEventListener, usePrevious, useThrottle } from 'ahooks'
import { ReactComponent as ArrowMicroIcon } from 'assets/images/arrow-bottom-micro.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/arrow-bottom.svg'
import { ReactComponent as ClearIcon } from 'assets/images/close-micro.svg'
import { ReactComponent as InfoIcon } from 'assets/images/info.svg'
import { ReactComponent as MarkIcon } from 'assets/images/mark.svg'
import cx from 'clsx'
import { useTranslate } from 'config/i18n'
import { OptionTreeItem } from 'interfaces/components.interfaces'
import { isEqual, uniqBy } from 'lodash'
import { deleteUndefinedInArray, toArray } from 'packages/helper'
import RcSelect, { SelectProps } from 'rc-select'
import { BaseSelectRef } from 'rc-select/lib/BaseSelect'
import { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select'
import { Button, ButtonColors, ButtonSizes } from 'ui/Button'
import { Loader, LoaderColors, LoaderTypes } from 'ui/Loader'
import { Tag, TagColors, TagSizes } from 'ui/Tag'
import { TextField } from 'ui/TextField'
import { Tooltip } from 'ui/Tooltip'
import { findOptionDeep } from 'utils/filter'
import { itemsTreeParse } from 'utils/itemsTreeParse'

import { parseLabel } from './parseLabel'
import classes from './Select.module.scss'

export enum SelectSizes {
  Default = 'default',
  Large = 'large',
  Small = 'small',
}

export enum SelectTypes {
  Default = 'default',
  Cell = 'cell',
  Header = 'header',
}

interface SelectPropsInternal<ValueType = string, Meta = unknown> {
  size?: SelectSizes
  isRound?: boolean
  type?: SelectTypes
  isCellTree?: boolean
  multiple?: boolean
  label?: string
  showSearch?: boolean
  classNameContainer?: string
  classNameSelect?: string
  classNameOpen?: string
  classNameOpenSelect?: string
  classNameLabel?: string
  classNameDisabled?: string
  options?: OptionTreeItem<Meta>[]
  isTree?: boolean
  defaultValue?: string | number | (string | number)[]
  onChange?: (value: ValueType, options?: OptionTreeItem<Meta>[]) => void
  defaultIsReset?: boolean
  offsetLeft?: number
  offsetTop?: number
  getPopupContainer?: () => HTMLElement | null
  closeSelectTrigger?: any
  loading?: boolean
  isFetchingNextPage?: boolean
  valueString?: string
  valueStringWhenNotLoaded?: string
  valueTreeWhenNotLoaded?: Record<number, string>
  onScrollPaginate?: () => void
  hasNextPage?: boolean
  onSearch?: (inputValue: string) => void
  actualSearchValue?: string
  dropdownRender?: (dropdown: ReactElement) => ReactElement
  showClear?: boolean
  widthMaxContent?: boolean
  isError?: boolean
  noEditable?: boolean
  isChanged?: boolean
  onOpenTree?: (key: string | number | null) => void
  showBorderWhenDisabled?: boolean
  checkedValues?: ValueType
  showSearchFromRequestOnly?: boolean
  loadingSearchResult?: boolean
  showApplyButton?: boolean
  showMultipleLabel?: boolean
  labelWithItems?: boolean
  isSelectWithChildren?: boolean
  dropdownMinWidth?: number
  showCounter?: boolean
  showSelectAll?: boolean
  isOptionEllipsis?: boolean
  listHeight?: number
  isValueCleared?: boolean
  selectedParentIds?: Record<number, number[]>
  selectedElementsNames?: Record<number, string>
  showSearchInputSingle?: boolean
  tooltip?: ReactNode
  showErrorTag?: boolean
}

const InternalSelect = <
  ValueType extends string | number | (number | string)[],
  OptionType extends DefaultOptionType | BaseOptionType = DefaultOptionType,
  Meta = unknown,
>(
  {
    className,
    classNameContainer,
    classNameSelect,
    classNameOpen,
    classNameOpenSelect,
    classNameLabel,
    classNameDisabled,
    dropdownClassName,
    children,
    size = SelectSizes.Default,
    isRound,
    type = SelectTypes.Default,
    isCellTree,
    multiple,
    placeholder,
    label,
    showSearch,
    onChange,
    options,
    isTree,
    defaultValue,
    defaultIsReset,
    value,
    onDropdownVisibleChange,
    open,
    offsetLeft = 0,
    offsetTop = 10,
    disabled,
    getPopupContainer,
    closeSelectTrigger,
    loading,
    isFetchingNextPage,
    valueString,
    valueStringWhenNotLoaded,
    valueTreeWhenNotLoaded,
    onScrollPaginate,
    hasNextPage,
    onSearch,
    actualSearchValue,
    dropdownRender,
    showClear,
    widthMaxContent,
    isError,
    noEditable,
    isChanged,
    onOpenTree,
    showBorderWhenDisabled,
    checkedValues,
    showSearchFromRequestOnly,
    loadingSearchResult,
    showApplyButton,
    showMultipleLabel,
    labelWithItems,
    isSelectWithChildren,
    dropdownMinWidth,
    listHeight = 435,
    showCounter,
    showSelectAll,
    isOptionEllipsis,
    isValueCleared,
    selectedParentIds,
    selectedElementsNames,
    showSearchInputSingle,
    tooltip,
    showErrorTag,
    ...props
  }: Omit<SelectProps<ValueType>, 'onChange' | 'mode' | 'getPopupContainer' | 'options'> &
    SelectPropsInternal<ValueType, Meta>,
  ref?: Ref<BaseSelectRef>,
) => {
  const [isTop, setIsTop] = useState(false)
  const [openInternal, setOpenInternal] = useState(open)
  const [selectedOptions, setSelectedOptions] = useState<OptionTreeItem<Meta>[]>([])
  const [valueInternal, setValueInternal] = useState<ValueType | null>()
  const [valueApplied, setValueApplied] = useState<ValueType | null>()
  const [searchValue, setSearchValue] = useState('')
  const [openTreeItems, setOpenTreeItems] = useState<(string | number | null)[]>([])

  const refSearchInput = useRef<HTMLInputElement>(null)

  const labelInternal = parseLabel<ValueType>({
    isTree,
    multiple,
    showMultipleLabel,
    options,
    valueInternal,
    valueStringWhenNotLoaded,
    valueTreeWhenNotLoaded,
    label,
    labelWithItems,
    selectedElementsNames,
    selectedOptions,
  })

  const valueCombine =
    (valueString as ValueType) ??
    (!multiple && !isTree && valueStringWhenNotLoaded && !options?.find((item) => item.value === valueInternal)
      ? (valueStringWhenNotLoaded as ValueType)
      : valueInternal)

  const translate = useTranslate()
  const refWrap = useRef<HTMLDivElement>(null)
  const throttledValue = useThrottle(searchValue, { wait: 200 })
  const actualSearchValueCombine = actualSearchValue ?? throttledValue
  const isSearch =
    !!searchValue &&
    !!actualSearchValueCombine &&
    (!showSearchFromRequestOnly || searchValue === actualSearchValueCombine)
  const isSearchPrevious = usePrevious(isSearch)
  const isSearchCombine =
    showSearchFromRequestOnly && !!actualSearchValueCombine && searchValue !== actualSearchValueCombine
      ? !!isSearchPrevious
      : isSearch

  const getCurrentOptions = (values: ValueType) => {
    const currentOptions = deleteUndefinedInArray(
      toArray(values).map((item) => findOptionDeep(item, options)),
    ) as OptionTreeItem<Meta>[]
    if (multiple) {
      currentOptions.push(...selectedOptions)
    }
    return uniqBy(currentOptions, 'value')
  }

  const onChangeInternal = (newValue: ValueType) => {
    let currentOptions = getCurrentOptions(newValue)
    if (!showApplyButton) {
      onChange?.(newValue, currentOptions)
    }
    if (!showSearch) {
      setSearchValue('')
    }
    if (
      Array.isArray(newValue) &&
      showSelectAll &&
      showApplyButton &&
      (newValue.includes('allSelect') || (options && newValue && newValue.length === options.length))
    ) {
      setValueInternal(['allSelect', ...(options?.map((item) => item.value) ?? [])] as ValueType)
      setSelectedOptions(options ?? [])
    } else {
      let savingValue = (Array.isArray(newValue) ? currentOptions.map((item) => item.value) : newValue) as ValueType
      currentOptions = currentOptions.filter((item) => {
        const isExtra = item.parentIds?.some((id) => toArray(savingValue).includes(id))
        if (isExtra) {
          savingValue = (
            Array.isArray(savingValue) ? savingValue.filter((valueId) => valueId !== item.value) : savingValue
          ) as ValueType
        }
        return !isExtra
      })
      setValueInternal(savingValue)
      setSelectedOptions(currentOptions)
    }
  }

  const onDeselect = (selectedValue?: string | number) => {
    requestAnimationFrame(() => {
      if (selectedValue === undefined) {
        return
      }
      const currentOption = findOptionDeep(selectedValue, options)
      if (!currentOption) {
        return
      }

      if (refSearchInput.current && searchValue !== refSearchInput.current.value) {
        return
      }

      setValueInternal((prev) => {
        if (selectedValue === 'allSelect') {
          setSelectedOptions([])
          return [] as never as ValueType
        }
        if (Array.isArray(prev)) {
          setSelectedOptions((items) => items.filter((item) => item.value !== selectedValue))
          return prev?.filter((item) => item !== 'allSelect' && item !== selectedValue) as ValueType
        }
        return prev
      })
    })
  }

  const apply = () => {
    setValueApplied(valueInternal)
    if (valueInternal) {
      onChange?.(
        Array.isArray(valueInternal) && valueInternal.includes('allSelect') && showApplyButton && showSelectAll
          ? (valueInternal.filter((item) => item !== 'allSelect') as ValueType)
          : valueInternal,
        selectedOptions,
      )
    }
  }

  const onSearchInternal = (inputValue: string) => {
    onSearch?.(inputValue)
    setSearchValue(inputValue)
  }

  const getPopupContainerInternal = (): HTMLElement => {
    const container: HTMLElement = document.querySelector('[data-container]') ?? document.body
    if (getPopupContainer) {
      return getPopupContainer() ?? container
    }
    return container
  }

  const dropdownRenderInternal = (dropdown: ReactElement) => (dropdownRender ? dropdownRender(dropdown) : dropdown)

  const onDropdownVisibleChangeInternal = (newOpen: boolean) => {
    if (newOpen) {
      const rect = refWrap.current?.getBoundingClientRect()
      if (rect && newOpen) {
        setIsTop(window.innerHeight - rect.top - rect.height < 470 && rect.top > 470)
      }
    }
    if (onDropdownVisibleChange) {
      onDropdownVisibleChange(newOpen)
    }
    setOpenInternal(newOpen)
  }

  const onOpenTreeInternal = (key: string | number | null) => {
    if (!key) {
      return
    }
    onOpenTree?.(key)
  }

  useEffect(() => {
    if (showSearchFromRequestOnly) {
      onSearch?.(throttledValue)
    }
  }, [throttledValue])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (!openInternal) {
      timer = setTimeout(() => {
        setIsTop(false)
      }, 1000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [openInternal])

  useEffect(() => {
    if (defaultValue && !value) {
      onChangeInternal(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    if (checkedValues) {
      const allCheckedValues =
        showSelectAll &&
        showApplyButton &&
        options &&
        Array.isArray(checkedValues) &&
        checkedValues.length === options.length
          ? (['allSelect', ...checkedValues] as ValueType)
          : checkedValues

      setValueInternal(allCheckedValues)
      setValueApplied(allCheckedValues)
    }
    if (!checkedValues && showApplyButton) {
      setValueInternal(undefined)
      setValueApplied(undefined)
    }
  }, [checkedValues])

  useEffect(() => {
    setOpenInternal(open)
  }, [open])

  useEffect(() => {
    if (isValueCleared) {
      setValueInternal(null)
    }
  }, [isValueCleared])

  useEffect(() => {
    if (typeof value !== 'undefined') {
      setValueInternal((prev) => {
        if (!isEqual(prev, value)) {
          return value
        }
        return prev
      })
      setValueApplied((prev) => {
        if (!isEqual(prev, value)) {
          return value
        }
        return prev
      })
    }
  }, [value])

  useEffect(() => {
    if (typeof closeSelectTrigger !== 'undefined') {
      onDropdownVisibleChangeInternal(false)
    }
  }, [closeSelectTrigger])

  useEventListener(
    'wheel',
    (event) => {
      if (!(event.target as HTMLElement | null)?.closest('.rc-select-dropdown')) {
        setOpenInternal(false)
      }
    },
    { target: getPopupContainerInternal() },
  )

  return (
    <div
      className={cx(
        classes.wrap,
        className,
        classes[size],
        {
          defaultReset: defaultIsReset && isEqual(valueInternal, defaultValue),
          'rc-select-wrap-open': openInternal,
          'rc-select-max-content': widthMaxContent,
        },
        classNameOpen && {
          [classNameOpen]: openInternal,
        },
        classNameDisabled && {
          [classNameDisabled]: disabled,
        },
        { [classes.disabled]: disabled, [classes.cell]: type === SelectTypes.Cell },
      )}
      ref={refWrap}
    >
      <div className={cx(classes.cont, classNameContainer)}>
        {((labelInternal && multiple) ||
          (defaultIsReset && isEqual(valueInternal, defaultValue)) ||
          (labelInternal && labelWithItems)) && (
          <span className={cx(classes.label, 'rc-label', classNameLabel)}>
            <span onClick={() => onDropdownVisibleChangeInternal(true)}>{labelInternal}</span>
            {showCounter &&
              isEqual(valueInternal || [], valueApplied || []) &&
              Array.isArray(valueApplied) &&
              valueApplied.length > (valueApplied.includes('allSelect') ? 2 : 1) && (
                <span className={classes.counter}>
                  +{valueApplied.length - (valueApplied.includes('allSelect') ? 2 : 1)}
                </span>
              )}
            {showApplyButton && !isEqual(valueInternal || [], valueApplied || []) && (
              <Button
                className={cx(classes.button, { [classes.tinyButton]: size === SelectSizes.Small })}
                color={openInternal ? ButtonColors.BorderWhite : ButtonColors.Border}
                onClick={apply}
                size={ButtonSizes.Small}
              >
                {translate('apply')}
              </Button>
            )}
          </span>
        )}

        <RcSelect
          allowClear={showClear && !!valueInternal}
          animation="slide-up"
          className={cx(
            classNameSelect,
            {
              'rc-select-round': isRound,
              'rc-select-cell': type === SelectTypes.Cell,
              [classes.header]: type === SelectTypes.Header,
              'rc-select-cell-tree': isCellTree,
              'rc-select-cell-selected': !!valueCombine,
              'rc-select-error': isError,
              'rc-select-no-editable': noEditable,
              'rc-select-no-editable-border': showBorderWhenDisabled,
              'rc-select-changed': isChanged,
              'rc-select-small': size === SelectSizes.Small,
              'rc-select-large': size === SelectSizes.Large,
              'rc-select-hide-label': labelWithItems,
            },
            classNameOpenSelect && {
              [classNameOpenSelect]: openInternal,
            },
          )}
          clearIcon={<ClearIcon />}
          data-type="text"
          disabled={disabled || noEditable}
          dropdownAlign={{
            offset: [offsetLeft, isTop ? -offsetTop : offsetTop],
            overflow: { adjustX: false, adjustY: false },
          }}
          dropdownRender={(menu) => (
            <div
              className={cx('rc-select-dropdown-cont', dropdownClassName, {
                'rc-multiple-dropdown': multiple,
                'rc-select-round': isRound,
                'rc-dropdown-cell': type === SelectTypes.Cell,
                'rc-dropdown-cell-tree': isCellTree,
                'rc-select-small': size === SelectSizes.Small || type === SelectTypes.Cell || isCellTree,
                'rc-select-large': size === SelectSizes.Large,
                'rc-select-ellipsis-options': isOptionEllipsis,
                'rc-select-fetching-next-page': isFetchingNextPage,
              })}
              style={{ minWidth: dropdownMinWidth ?? 'auto' }}
            >
              {dropdownRenderInternal(
                <>
                  {((!loading && multiple) || showSearchInputSingle) && showSearch && (
                    <div className={classes.search}>
                      <TextField
                        isCleanable
                        onChange={setSearchValue}
                        placeholder={translate('search')}
                        ref={refSearchInput}
                        value={searchValue}
                      />
                    </div>
                  )}
                  {!loading && menu}
                  {loading && (
                    <Loader
                      className={cx(classes.loader, { [classes.loaderPl]: isCellTree })}
                      type={LoaderTypes.Text}
                    />
                  )}
                  <Loader className="rc-select-loader-paginate" color={LoaderColors.Dark} />
                </>,
              )}
            </div>
          )}
          filterOption={
            !isTree
              ? (inputValue, option) =>
                  typeof option?.label === 'string' && option?.label.toLowerCase().includes(inputValue.toLowerCase())
              : undefined
          }
          getPopupContainer={getPopupContainerInternal}
          inputIcon={type === SelectTypes.Cell || isCellTree ? <ArrowMicroIcon /> : <ArrowIcon />}
          listHeight={listHeight}
          menuItemSelectedIcon={<MarkIcon />}
          mode={multiple ? 'multiple' : undefined}
          notFoundContent={
            loadingSearchResult ? (
              <Loader className={classes.loaderDropDown} type={LoaderTypes.Text} />
            ) : (
              translate('notFound')
            )
          }
          onChange={onChangeInternal}
          onDeselect={onDeselect}
          onSearch={showSearch && !showSearchFromRequestOnly ? onSearchInternal : undefined}
          options={
            !isTree
              ? (options
                  ? showSelectAll && multiple
                    ? [
                        {
                          label: translate('selectAll'),
                          value: 'allSelect',
                          className: 'rc-select-item-option-underlined',
                        } as OptionType & OptionTreeItem,
                        ...options,
                      ]
                    : options
                  : undefined
                )?.map((option) => ({
                  ...option,
                  title: '',
                }))
              : undefined
          }
          placeholder={labelInternal && multiple ? undefined : placeholder ?? label}
          ref={ref}
          searchValue={!showSearchFromRequestOnly ? searchValue : undefined}
          showArrow={!showClear || !valueInternal}
          showSearch={!showSearchFromRequestOnly ? showSearch : false}
          {...props}
          dropdownClassName={isTop ? 'dropdown-top' : undefined}
          onDropdownVisibleChange={onDropdownVisibleChangeInternal}
          onPopupScroll={(e) => {
            const target = e.currentTarget
            if (!loading && hasNextPage && target.scrollHeight - target.scrollTop - target.clientHeight < 100) {
              onScrollPaginate?.()
            }
          }}
          open={openInternal}
          placement={isTop ? 'topLeft' : 'bottomLeft'}
          value={valueCombine}
        >
          {!isTree
            ? children
            : itemsTreeParse({
                itemsTree: options,
                openTreeItems,
                setOpenTreeItems,
                isSearch: isSearchCombine,
                onOpenTree: onOpenTreeInternal,
                checkedValues: valueCombine,
                selectedParentIds,
                isSelectable: true,
                multiple,
                selectedOptions,
                isSelectWithChildren,
              })}
        </RcSelect>
      </div>

      {isError && showErrorTag && (
        <Tooltip className={classes.tooltip} position="top" tooltip={tooltip}>
          <Tag className={classes.error} color={TagColors.Danger} icon={<InfoIcon />} size={TagSizes.Small}>
            {translate('error')}
          </Tag>
        </Tooltip>
      )}
    </div>
  )
}

export const Select = forwardRef(InternalSelect) as <ValueType = string, Meta = unknown>(
  props: Omit<SelectProps<ValueType>, 'onChange' | 'mode' | 'getPopupContainer' | 'options'> &
    SelectPropsInternal<ValueType, Meta> & { ref?: Ref<BaseSelectRef> },
) => ReactElement
