import { useEffect } from 'react'

import { useSafeState } from 'ahooks'

export const useIsMountedMs = (isOpened = false, ms = 300) => {
  const [isMounted, setIsMounted] = useSafeState(false)

  useEffect(() => {
    let timer: NodeJS.Timeout
    setIsMounted((prev) => {
      if (!prev) {
        return isOpened
      }
      if (!isOpened) {
        timer = setTimeout(() => {
          setIsMounted(false)
        }, ms)
      }
      return prev
    })
    return () => {
      clearTimeout(timer)
    }
  }, [isOpened])

  return [isMounted, setIsMounted]
}
