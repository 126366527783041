import { forwardRef, ReactNode } from 'react'

import { Modal } from 'components/template/Modal'
import { OverlayingModal } from 'components/template/Modal/OverlayingModal'

interface TextModalProps {
  className?: string
  isOpened?: boolean
  onClose?: () => void
  title?: ReactNode
  children?: ReactNode
}

export const TextModal = forwardRef<HTMLDivElement, TextModalProps>(
  ({ className, isOpened, onClose, title, children }, ref) => (
    <OverlayingModal isOpened={isOpened} onClose={onClose} ref={ref}>
      <Modal className={className} onClose={onClose} title={title}>
        {children}
      </Modal>
    </OverlayingModal>
  ),
)
