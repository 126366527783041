import { forwardRef, ReactNode, useEffect, useState } from 'react'

import { ReactComponent as CheckIcon } from 'assets/images/check.svg'
import cx from 'clsx'

import classes from './Checkbox.module.scss'

export enum CheckboxColors {
  Default = 'default',
  White = 'white',
}

interface CheckboxProps {
  children?: ReactNode
  className?: string
  classNameCheckbox?: string
  classNameLabel?: string
  disabled?: boolean
  value?: boolean
  onChange?: (value: boolean) => void
  color?: CheckboxColors
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      classNameCheckbox,
      classNameLabel,
      value,
      disabled,
      onChange,
      children,
      color = CheckboxColors.Default,
    },
    ref,
  ) => {
    const [valueInternal, setValueInternal] = useState(false)

    const onChangeInternal = () => {
      setValueInternal((prev) => {
        window.requestAnimationFrame(() => onChange?.(!prev))
        return !prev
      })
    }

    useEffect(() => {
      setValueInternal(!!value)
    }, [value])

    return (
      <div
        className={cx(classes.wrap, className, classes[color], {
          [classes.checked]: valueInternal,
          [classes.disabled]: disabled,
          [classes.noStretch]: !children,
        })}
        onClick={onChangeInternal}
      >
        <input className={classes.input} onChange={onChangeInternal} ref={ref} type="checkbox" />
        <div className={cx(classes.checkbox, classNameCheckbox, { [classes.margin]: children })}>
          <CheckIcon className={classes.icon} />
        </div>
        {!!children && <div className={cx(classes.label, classNameLabel)}>{children}</div>}
      </div>
    )
  },
)
