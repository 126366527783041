import { FC, ReactNode, useEffect, useState } from 'react'

import cx from 'clsx'
import { Modal } from 'components/template/Modal'
import { OverlayingModal } from 'components/template/Modal/OverlayingModal'
import { i18n } from 'config/i18n'
import { Button, ButtonColors, ButtonSizes } from 'ui/Button'
import { ButtonEasy } from 'ui/ButtonEasy'

import classes from './ConfirmModal.module.scss'

interface ConfirmModalProps {
  className?: string
  classNameButtons?: string
  isOpened?: boolean
  disabledScroll?: boolean
  onClose?: () => void
  title?: ReactNode
  danger?: boolean
  cancelText?: string
  okText?: string
  onCancel?: () => void
  onOk?: () => void
  closeAfterSuccess?: boolean
  loading?: boolean
  isButtonEasyModal?: boolean
  isCancelButtonHide?: boolean
  children?: ReactNode
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  className,
  classNameButtons,
  isOpened,
  disabledScroll,
  onClose,
  title,
  danger,
  cancelText = i18n.get('cancel'),
  okText = 'OK',
  onCancel,
  onOk,
  closeAfterSuccess,
  loading,
  isButtonEasyModal,
  isCancelButtonHide,
  children,
}) => {
  const [pressOk, setPressOk] = useState<boolean>(false)

  const onCancelInternal = () => {
    if (onCancel) {
      onCancel()
    }
    if (onClose) {
      onClose()
    }
  }

  const onOkInternal = () => {
    if (onOk) {
      onOk()
    }
    if (!closeAfterSuccess && onClose) {
      onClose()
    }
    setPressOk(true)
  }

  useEffect(() => {
    if (closeAfterSuccess && pressOk && !loading && onClose) {
      setPressOk(false)
      onClose()
    }
  }, [pressOk, loading, closeAfterSuccess, onClose])

  return (
    <OverlayingModal disabledScroll={disabledScroll} isOpened={isOpened} onClose={onClose}>
      {!isButtonEasyModal ? (
        <Modal
          className={cx(classes.popup, className)}
          classNameTitle={cx(classes.title, { [classes.titleWithChildren]: children })}
          onClose={onClose}
          title={title}
        >
          {children}
          <div className={cx(classes.buttons, classNameButtons, { [classes.oneButton]: isCancelButtonHide })}>
            {!isCancelButtonHide && (
              <Button color={ButtonColors.Border} onClick={onCancelInternal}>
                {cancelText}
              </Button>
            )}
            <Button
              color={danger ? ButtonColors.Danger : ButtonColors.Default}
              loading={loading}
              onClick={onOkInternal}
            >
              {okText}
            </Button>
          </div>
        </Modal>
      ) : (
        <div className={classes.buttonEasyPopup}>
          <ButtonEasy label={okText} loading={loading} onClick={onOkInternal} showLabelAlways>
            {title}
          </ButtonEasy>
          <Button
            className={classes.buttonEasyCancel}
            color={ButtonColors.Text}
            onClick={onCancelInternal}
            size={ButtonSizes.Small}
          >
            {i18n.get('unSave')}
          </Button>
        </div>
      )}
    </OverlayingModal>
  )
}
